import Vue from 'vue';
import moment from 'moment';
import * as consts from '../consts';

const filters = [
  {
    name: 'truncate',
    execute: (text, length, suffix = '...') => {
      if (text !== undefined) {
        if (text.length > length) {
          return text.substring(0, length) + suffix;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
  },
  {
    name: 'datepl',
    execute: (text, length) => {
      if (text) {
        if (text.toString().length >= 10) {
          const dt = text.includes('-') ? moment(text, consts.DB_DATE_TIME_FORMAT) : moment(text, consts.DATE_FORMAT);
          const str = dt.format(consts.DATE_TIME_FORMAT);
          if (length !== undefined && length > 0) {
            return str.substring(0, length);
          }
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
  },
  {
    name: 'fill',
    execute: (text: string | number, length: number, char = '0', side = 'left') => {
      if (text !== undefined) {
        if (side === 'left') {
          return text.toString().padStart(length, char);
        } else {
          return text.toString().padEnd(length, char);
        }
      } else {
        return text;
      }
    },
  },
];

export default filters;
