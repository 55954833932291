















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class TextSection extends Vue{
  @Prop({ type: String, required: false, default: "" }) text;
}
