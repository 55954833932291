




















import auth from '../store/modules/auth';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from '../store/api';
import { VueCookies } from 'vue-cookies';
import * as helpers from '../helpers';

@Component({
  components: {},
})
export default class Test extends Vue {
  async headers() {
    await API.getCSRF();
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRFToken': auth.csrf,
    };
    return { withCredentials: true, headers: headers };
  }

  // USER CAR
  async getUserCars() {
    await API.corplineApi.get('/get_user_cars/107').then(response => {
      console.log(response);
      helpers.info('Pobrane..');
    });
  }
  async addUserCar() {
    await API.corplineApi
      .post('/save_user_car', { user_id: 107, vin: '1010930850990' }, await this.headers())
      .then(response => {
        console.log(response);
        helpers.info('Dodano..');
      });
  }
  async deleteUserCar() {
    await API.corplineApi.delete('/delete_user_car/107/1010930850990', await this.headers()).then(response => {
      console.log(response);
      helpers.info('Usuniete..');
    });
  }

  // USER STARRED
  async getUserStarred() {
    await API.corplineApi.get('/get_user_starred/107').then(response => {
      console.log(response);
      helpers.info('Pobrane..');
    });
  }
  async addUserStarred() {
    await API.corplineApi
      .post('/save_user_starred', { user_id: 121, vin: 'WDDHF8HB8AA148243', auction_type: 2 }, await this.headers())
      .then(response => {
        console.log(response);
        helpers.info('Dodano..');
      });
  }
  async deleteUserStarred() {
    await API.corplineApi.delete('/delete_user_starred/107/1010930850990', await this.headers()).then(response => {
      console.log(response);
      helpers.info('Usuniete..');
    });
  }
}
