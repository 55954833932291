
























import { Component, Vue, Prop} from 'vue-property-decorator';
import * as consts from '../../consts';
@Component({
  components: {
  },
})
export default class ModalDeleteUser extends Vue {

  size = 'sm';
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];

  showModal(params = {}) {
    this.show = true;
  }

  hideModal() {
    this.show = false;
  }
  deleteUser(){
    console.log('deleteUser');
  }

}
