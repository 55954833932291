













































































































import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import FileInput from '@/components/dummy/FileInput.vue';
import AccountBasicDataEdit from '@/components/spec/AccountBasicDataEdit.vue';
import ModalInfo from "@/components/dummy/ModalInfo.vue";
import * as API from '@/store/api';
import {StatusCodes} from "@/consts";

@Component({
  components: {
    AccountBasicDataEdit,
    FileInput,
    ModalInfo,
  },
})
export default class AccountBasicData extends Vue {
  @Ref() readonly InfoModal: ModalInfo;
  @Prop({ type: Boolean, required: false, default: false }) isAdmin;
  @Prop({required: false, default: null }) adminViewUserId;
  @Prop({required: false, default: null }) adminViewAccountData;
  changedImageUrl = null;
  fileSource = null;
  editDataVisible = false;
  getClientResponse = null;
  accountData = {
    contact: {
      name: null,
      street_and_house_no: null,
      city: null,
      postal_code: null,
      phone: null,
    },
    invoice: {
      name: null,
      nip: null,
      street_and_house_no: null,
      postal_code: null,
      city: null,
    },
    delivery: {
      name: null,
      street_and_house_no: null,
      postal_code: null,
      city: null,
      point_type: null,
      phone: null,
    },
  }
  loading = false;
  infoModalTitle = '';
  infoModalText = '';
  imageFileChange(fileSrc) {
    this.changedImageUrl = fileSrc.url;
    this.fileSource = fileSrc.file;
  }
  toggleDataContent() {
    this.editDataVisible = !this.editDataVisible;
  }
  @Emit('delete-user')
  deleteUser() {
    return true;
  }

  get userId() {
    if(this.adminViewUserId){
      return this.adminViewUserId;
    }
    return this.$store.state.currentUser.id;
  }
  showModalInfo(){
    this.InfoModal.showModal();
  }
  async saveAccount(accountData) {
    this.loading = true;
    const formData = new FormData();
    formData.append('user_id', this.userId);
    formData.append('file', this.fileSource);
    formData.append('client', JSON.stringify(accountData));
    const saveClientResponse = await API.saveClient(formData);
    this.infoModalTitle = 'Błąd';
    this.infoModalText = 'Zmiany nie zostały wprowadzone. Spróbuj ponownie później.';
    if(saveClientResponse){
      if(saveClientResponse.status === 200){
        this.infoModalTitle = 'Gotowe';
        this.infoModalText = 'Zmiany zostały wprowadzone';
      }
    }
    this.showModalInfo();
    this.loading = false;
  }
  async mounted() {
    if(!this.adminViewAccountData){
      this.getClientResponse = await API.getClient(this.userId);
      this.getClientResponse = this.getClientResponse.client;
      if(this.getClientResponse !== StatusCodes.NOT_FOUND){
        this.changedImageUrl = 'data:image/jpg;base64,' + this.getClientResponse.avatar;

        this.accountData.contact.name = this.getClientResponse.contact.name;
        this.accountData.contact.street_and_house_no = this.getClientResponse.contact.street_and_house_no;
        this.accountData.contact.city = this.getClientResponse.contact.city;
        this.accountData.contact.postal_code = this.getClientResponse.contact.postal_code;
        this.accountData.contact.phone = this.getClientResponse.contact.phone;

        this.accountData.invoice.name = this.getClientResponse.invoice.name;
        this.accountData.invoice.nip = this.getClientResponse.invoice.nip;
        this.accountData.invoice.street_and_house_no = this.getClientResponse.invoice.street_and_house_no;
        this.accountData.invoice.postal_code = this.getClientResponse.invoice.postal_code;
        this.accountData.invoice.city = this.getClientResponse.invoice.city;

        this.accountData.delivery.name = this.getClientResponse.delivery.name;
        this.accountData.delivery.street_and_house_no = this.getClientResponse.delivery.street_and_house_no;
        this.accountData.delivery.postal_code = this.getClientResponse.delivery.postal_code;
        this.accountData.delivery.city = this.getClientResponse.delivery.city;
        this.accountData.delivery.point_type = this.getClientResponse.delivery.point_type;
        this.accountData.delivery.phone = this.getClientResponse.delivery.phone;

      }

    }else{
      this.accountData = this.adminViewAccountData;
      console.log(this.accountData);
      this.changedImageUrl = 'data:image/jpg;base64,' + this.adminViewAccountData.avatar;

    }
    this.$forceUpdate();
  }
}
