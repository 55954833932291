





















import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class DataListInput extends Vue{
  dataListVal = '';
  // try to set default val to v-model
  @Prop({ type: Number, required: false }) min;
  @Prop({ type: Number, required: false }) max;
  @Prop({ type: Number, required: false }) step;
  @Prop({ type: String, required: false }) inputType;
  @Prop({ type: Boolean, required: false, default: true}) withDataList;
  @Prop({ required: false, default: '' }) value;
  @Prop({ type: String, required: false, default: 'type-1' }) type;
  @Prop({ type: Boolean, required: false, default: false }) isJson;
  @Prop({ type: String, required: false }) jsonKeyValue;
  @Prop({ type: String, required: false }) jsonLabelValue;
  @Prop({ type: String, required: false, default: '' }) label;
  @Prop({ type: Array, required: false }) list;
  @Prop({ type: String, required: false, default: 'data-list' }) id;
  @Prop({ type: Boolean, required: false, default: false }) inputDisabled;


  @Watch('value', { immediate: true })
  valueChange(val, oldVal) {
    this.value = val;
    this.setDefaultVal();
  }
  @Watch('list', { immediate: true })
  listChange(val, oldVal) {
    this.list = val;
  }

  @Emit("datalist-value")
  dataListValue(){
    if(this.isJson){
      for(let i = 0; i < this.list.length; i++){
        const value = this.list[i];
        if(value[this.jsonLabelValue] === this.dataListVal){
          return value[this.jsonKeyValue];
        }
      }
      return this.dataListVal;
    }else{
      return this.dataListVal;
    }
  }
  clearValue(){
    this.dataListVal = '';
    this.dataListValue();
  }
  setDefaultVal(){
    let returnVal = '';
    this.dataListVal = '';
      if(typeof this.value !== 'undefined'){
        if(this.value.length){
          this.dataListVal = this.value;
          returnVal = this.value;
        }
    }
    this.dataListValue();
    return returnVal;
  }
  mounted(){
    this.setDefaultVal()
  }
}
