



























import {Vue, Component, Prop, Emit} from "vue-property-decorator";
@Component({})
export default class PasswordResetFormPart1 extends Vue{
  passwordResetPart1Form =  {
    email: '',
  }

  @Emit("password-reset-form-part-1-approved")
  passwordResetFormPart2Submit(event){
    event.preventDefault()
    return this.passwordResetPart1Form;
  }
  @Emit('cancel')
  cancel(){
    return true;
  }

}
