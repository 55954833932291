




















































import {Component, Vue, Ref, Prop, Emit, Watch} from 'vue-property-decorator';
import ModalLogin from "@/components/spec/ModalLogin.vue";
import ModalPasswordResetPart1 from "@/components/spec/ModalPasswordResetPart1.vue";
import ModalPasswordResetPart2 from "@/components/spec/ModalPasswordResetPart2.vue";
import ModalRegistration from "@/components/spec/ModalRegistration.vue";
import ModalInfo from "@/components/dummy/ModalInfo.vue";
import ModalAccountDetails from "@/components/spec/ModalAccountDetails.vue";

@Component({
  components: {
    ModalLogin,
    ModalRegistration,
    ModalInfo,
    ModalAccountDetails,
    ModalPasswordResetPart1,
    ModalPasswordResetPart2
  },
})
export default class HeaderLoginButton extends Vue{
  @Ref() readonly LoginFormModal: ModalLogin;
  @Ref() readonly RegisterFormModal: ModalRegistration;
  @Ref() readonly InfoModal: ModalInfo;
  @Ref() readonly AccountDetailsModal: ModalAccountDetails;
  @Ref() readonly ResetPasswordPart1Modal: ModalPasswordResetPart1;
  @Ref() readonly ResetPasswordPart2Modal: ModalPasswordResetPart2;

  @Prop({ default: false }) isLoggedIn;
  @Prop({ default: false }) modalAccountDetailsVisible;
  @Watch("modalAccountDetailsVisible", {deep: true})
  changeModalAccountDetailsVisibility(val, oldVal){
      this.showAccountDetailsModal();
  }

  loginModalTitle = 'Logowanie Corpline';
  registrationModalTitle = 'Rejestracja Corpline';
  defaultModalTitle = 'Gotowe!';
  defaultModalText = 'Możesz zamknąć to okno';
  infoModalTitle = this.defaultModalTitle;
  infoModalText = this.defaultModalText;
  reset1ModalTitle = 'Zmiana hasła';
  reset1ModalText = 'Podaj swój adres e-mail, który podałeś podczas zakładania konta.';
  reset2ModalTitle = 'Zmiana hasła';
  reset2ModalText = 'Wprowadź swoje nowe hasło przyporządkowanie do konta. Hasło musi składać się z minimum sześciu znaków.';
  passwordResetPart1Form = {
    email: '',
  };
  passwordResetPart2Form = {
    password: '',
    repeatedPassword: '',
  }
  showLoginModal(){
    this.LoginFormModal.showModal();
  }
  showAccountDetailsModal(){
    this.AccountDetailsModal.showModal();
  }
  registerSuccess(){
    this.LoginFormModal.hideModal();
    this.infoModalTitle = 'Konto zostało zarejestrowane'
    this.infoModalText = 'Na podany adres e-mail wysłana została wiadomość z linkiem aktywacyjnym.'
    this.InfoModal.showModal();
  }
  showRegistration(){
    this.LoginFormModal.hideModal();
    this.RegisterFormModal.showModal();
  }
  @Emit('login-success')
  loginSuccess(){
    this.LoginFormModal.hideModal();
    this.$router.go(0);
  }
  hideRegisterSuccess(){
    console.log('hideRegisterSuccess()');
  }
  hideModalAccountDetails(){
    this.infoModalTitle = 'Proces uzupełniania konta został zakończony!'
    this.infoModalText = 'Teraz możesz w pełni cieszyć się możliwościami, jakie daje Ci Corpline.';
    this.InfoModal.showModal();
  }
  forgottenPassword(){
    this.LoginFormModal.hideModal();
    this.ResetPasswordPart1Modal.showModal();
  }
  resetPasswordPart1Cancel(){
    this.ResetPasswordPart1Modal.hideModal();
  }
  resetPasswordPart1Success(passwordResetPart1Form){
    // not working. waiting for connect to api
    this.passwordResetPart1Form = passwordResetPart1Form;
    this.ResetPasswordPart1Modal.hideModal();
    this.infoModalTitle = 'Zmiana hasła'
    this.infoModalText = 'Na adres ' + this.passwordResetPart1Form.email + ' przesłaliśmy link prowadzący do kolejnego kroku. Prosimy sprawdź swoją pocztę, następnie klikając w link przejdziesz do strony z formularzem umożliwiającym wprowadzenie nowego hasła.'
    console.log(this.infoModalText);
  }
  resetPasswordPart2Cancel(){
    this.ResetPasswordPart1Modal.hideModal();
  }
  resetPasswordPart2Success(passwordResetPart2Form){
    // not working. waiting for connect to api
    this.passwordResetPart2Form = passwordResetPart2Form;
    this.ResetPasswordPart2Modal.hideModal();
    this.infoModalTitle = 'Zmiana hasła przebiegła prawidłowo';
    this.infoModalText = 'Gratulacje. Twoje nowe hasło zostało zmienione. Teraz możesz przejść do strony logowania.';
    console.log(this.infoModalText);
  }
}
