










import {Component, Vue} from 'vue-property-decorator';
import ExciseTaxCostCalculator from "@/components/dummy/ExciseTaxCostCalculator.vue";
import PurchaseCostCalculator from "@/components/dummy/PurchaseCostCalculator.vue";

@Component({
  components: {
    ExciseTaxCostCalculator,
    PurchaseCostCalculator,
  },
})
export default class Calculators extends Vue {
  stateSymbol = '-';
}
