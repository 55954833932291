










































































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import ModalAddEditTransaction from "@/components/spec/ModalAddEditTransaction.vue";
import ModalDeleteTransaction from "@/components/spec/ModalDeleteTransaction.vue";
import * as API from "@/store/api";
@Component({
  components: {
    ModalDeleteTransaction,
    ModalAddEditTransaction,
  },
})
export default class CRMUserTransactionsListTable extends Vue {
  @Ref() readonly AddEditTransactionModal: ModalAddEditTransaction;
  @Ref() readonly DeleteTransactionModal: ModalDeleteTransaction;
  @Prop({required: true, default: null }) userId;
  @Prop({required: false, type: String, default: null }) title;
  isModalInEditView = false;

  userTransactions = null;
  tmpModalTransaction = null;
  modalTransactionTitle = 'Dodaj transakcję';
  showAddTransactionModal(){
    this.isModalInEditView = false;
    this.tmpModalTransaction = null;
    this.modalTransactionTitle = 'Dodaj transakcję';
    this.AddEditTransactionModal.showModal();
  }
  showEditTransactionModal(transaction){
    this.isModalInEditView = true;
    this.tmpModalTransaction = transaction;
    this.modalTransactionTitle = 'Edytuj transakcję';
    this.AddEditTransactionModal.showModal();
  }
  showDeleteTransactionModal(transaction){
    this.tmpModalTransaction = transaction;
    this.DeleteTransactionModal.showModal();
  }
  async getUserTransactions() {
    const response = await API.getUserTransactions(this.userId);
    if (response.success) {
      this.userTransactions = response.user_transactions;
    }
  }
  modalResponse(){
    this.getUserTransactions();
  }
  transactionRemove(){
    this.getUserTransactions();
  }

  mounted(){
    this.getUserTransactions();
  }
}
