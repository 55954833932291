















































































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';

@Component({
  mixins: [validationMixin],
  validations: {
    registrationForm: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      },
      repeatedPassword: {
        sameAsPassword: sameAs('password')
      },
      checked: {
        required
      },
    },
  }
})
export default class RegistrationForm extends mixins(Vue, validationMixin) {
  @Prop() public display!: boolean;

  registrationForm = {
    email: '',
    password: '',
    repeatedPassword: '',
    checked: '',
  }
  submitStatus = ''
  registerCheckboxStatus = null;
  checkboxCheck(event){
    if (event) {
      this.registerCheckboxStatus = true;
      return true;
    }else{
      this.registerCheckboxStatus = false;
      return false;
    }
  }

  @Emit("registration-form-validated")
  registerSubmit(){
    if(this.checkboxCheck(this.registrationForm.checked) == true){
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false;
      } else {
        return this.registrationForm;
      }
    }else{
      return false;
    }
  }
}
