import axios, { AxiosResponse } from 'axios';
import * as types from '@/types';
import * as consts from '@/consts';
import { ADMIN_USER_ID, OrderStatuses, StatusCodes } from '@/consts';
import auth from '../store/modules/auth';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL + 'api/';

export const corplineApi = axios.create();

// to uzyjesz jak bedziemy wysylac zalaczniki na serwer
//if (isFormData === true) {
//headers['Content-type'] = 'multipart/form-data;boundary=63c5979328c44e2c869349443a94200e';
//}

// Admin
export async function clearDB(): Promise<AxiosResponse> {
  return await corplineApi.post('/clear_db');
}
export async function log(message: string): Promise<AxiosResponse> {
  return await corplineApi.post('/log', { message });
}
export async function fireScript(scriptName: string): Promise<AxiosResponse> {
  return await corplineApi.post('/fire_script', { script_name: scriptName });
}

// Auth
export async function getCSRF() {
  await corplineApi
    .get('/csrf', {
      withCredentials: true,
    })
    .then(res => {
      const csrfToken = res.headers['x-csrftoken'];
      auth.setCsrf(csrfToken);
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getSession() {
  await corplineApi
    .get('/session', {
      withCredentials: true,
    })
    .then(async response => {
      auth.setAuthenticated(response.data.isAuthenticated);
      auth.setSuperUser();
      if (auth.isAuthenticated === false) {
        await getCSRF();
        await auth.setAuthenticated(response.data.isAuthenticated);
      }
    })
    .catch(err => {
      console.log(err);
    });
}

export async function loginUser(user: types.UserSubmit) {
    await getCSRF();
    const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': auth.csrf,
    };
    let loginDataResponse;
    let loginCodeResponse;
    await corplineApi
        .post('/login', { email: user.email, password: user.password }, { withCredentials: true, headers: headers })
        .then(response => {
            if (response.data.isAuthenticated) {
                auth.login(response.data.user);
                const csrfToken = response.headers['x-csrftoken'];
                auth.setCsrf(csrfToken);
                loginCodeResponse = StatusCodes.OK;
                loginDataResponse = response.data;
            } else {
                auth.clear();
                loginCodeResponse = StatusCodes.UNAUTHORIZED;
            }
        })
        .catch(err => {
            if (err.response) {
                loginCodeResponse = err.response.data.error_code;
            }
        });
    return { code: loginCodeResponse, data: loginDataResponse };
}

export async function registerUser(user: types.UserSubmit) {
  await getCSRF();
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  let registerResponse;
  await corplineApi
    .post('/register', { email: user.email, password: user.password }, { withCredentials: true, headers: headers })
    .then(response => {
      registerResponse = StatusCodes.OK;
    })
    .catch(function (error) {
      if (error.response) {
        registerResponse = error.response.status;
      }
    });
  return registerResponse;
}
export async function activateUser({ email, token }) {
  await getCSRF();
  let activateResponse;
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  await corplineApi
    .post('/activate', { email: email, token: token }, { withCredentials: true, headers: headers })
    .then(response => {
      activateResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        activateResponse = err.response.status;
      }
    });
  return activateResponse;
}
export async function reset(email: string): Promise<any> {
  const response = await corplineApi.post('/reset_request', { email });
  return response;
}
export async function logoutUser() {
  const response = await corplineApi.post(
    '/logout',
    {},
    { withCredentials: true, headers: { 'X-CSRFToken': auth.csrf } }
  );
  return response;
}

export async function getAuctions(auctionsParameter) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionsResponse;
  await corplineApi
    .post('/auctions', auctionsParameter, { withCredentials: true, headers: headers })
    .then(response => {
      auctionsResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        auctionsResponse = err.response.status;
      }
    });
  return auctionsResponse;
}

export async function getSingleAuctionCopart(lotNumber) {
  const auctionsParameter = {
    page: 1,
    pageSize: 1,
    sortParams: {
      field: 'high_bid',
      dir: 'DESC',
    },
    filters: {
      lot_number: lotNumber,
    },
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionsResponse;
  await corplineApi
    .post('/auctions', auctionsParameter, { withCredentials: true, headers: headers })
    .then(response => {
      auctionsResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        auctionsResponse = err.response.status;
      }
    });
  return auctionsResponse;
}

export async function getAuctionImages(auctionParameter) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionResponse;
  await corplineApi
    .post('/copart_proxy', auctionParameter, { withCredentials: true, headers: headers })
    .then(response => {
      auctionResponse = response.data;
    })
    .catch(err => {
      console.log(err);
      if (err.response) {
        auctionResponse = err.response.status;
      }
    });
  return auctionResponse;
}

export async function getPurchasedCargoloop(VIN, id?) {
  let urlValue = '';
  let response;
  if (VIN.length) {
    urlValue = `/cargoloop_proxy?vin=${VIN}`;
  } else {
    urlValue = `/cargoloop_proxy?id=${id}`;
  }
  await corplineApi
    .get(urlValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = StatusCodes.NOT_FOUND;
    });
  return response;
}

export async function getDataFromCopartDict(fieldName) {
  let response;
  if (fieldName.length) {
    const urlValue = `/copart_dict?field=${fieldName}`;
    await corplineApi
      .get(urlValue, {
        withCredentials: true,
      })
      .then(res => {
        response = res.data;
      })
      .catch(err => {
        if (err.response) {
          response = err.response.status;
        }
      });
    return response;
  } else {
    return -1;
  }
}

export async function getDataFromCopartDictFiltered(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionResponse;
  await corplineApi
    .post('/copart_dict_filtered', params, { withCredentials: true, headers: headers })
    .then(response => {
      auctionResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        auctionResponse = err.response.status;
      }
    });
  return auctionResponse;
}

export async function getDataFromIaaiDict(fieldName) {
  let response;
  if (fieldName.length) {
    const urlValue = `/iaai_dict?field=${fieldName}`;
    await corplineApi
      .get(urlValue, {
        withCredentials: true,
      })
      .then(res => {
        response = res.data;
      })
      .catch(err => {
        if (err.response) {
          response = err.response.status;
        }
      });
    return response;
  } else {
    return -1;
  }
}
export async function getDataFromIaaIDictFiltered(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionResponse;
  await corplineApi
    .post('/iaai_dict_filtered', params, { withCredentials: true, headers: headers })
    .then(response => {
      auctionResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        auctionResponse = err.response.status;
      }
    });
  return auctionResponse;
}

export async function getNewDataFromCopartCsv() {
  await corplineApi
    .get('/reload_csv', {
      withCredentials: true,
    })
    .then(res => {
      const csrfToken = res.headers['x-csrftoken'];
      auth.setCsrf(csrfToken);
    })
    .catch(err => {
      console.log(err);
    });
}

export async function getIaaiAuctions(auctionsParameter) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionsResponse;
  await corplineApi
    .post('/iaai_auctions', auctionsParameter, { withCredentials: true, headers: headers })
    .then(response => {
      auctionsResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        auctionsResponse = err.response.status;
      }
    });
  return auctionsResponse;
}


export async function getSingleAuctionIaai(stockNo) {
  const auctionsParameter = {
    page: 1,
    pageSize: 1,
    filters: {
      stock_no: stockNo,
    },
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  let auctionsResponse;
  await corplineApi
      .post('/iaai_auctions', auctionsParameter, { withCredentials: true, headers: headers })
      .then(response => {
        auctionsResponse = response.data;
      })
      .catch(err => {
        if (err.response) {
          auctionsResponse = err.response.status;
        }
      });
  return auctionsResponse;
}



export async function getJSONIaaiVehicle(htmlCode) {
  htmlCode = new DOMParser().parseFromString(htmlCode, 'text/html');
  let JSONData = htmlCode.getElementById('ProductDetailsVM');
  if (typeof JSONData !== 'undefined' && JSONData !== null) {
    JSONData = JSON.parse(JSONData.innerHTML);
    return JSONData;
  } else {
    return StatusCodes.NOT_FOUND;
  }
}

export async function getIaaiVehicle(vehicleHref) {
  let auctionsResponse;
  vehicleHref = consts.IAAI_HOST_VALUE + vehicleHref;
  await axios
    .get(vehicleHref)
    .then(async response => {
      auctionsResponse = [StatusCodes.OK, response.data];
      auctionsResponse = await getJSONIaaiVehicle(response.data);
    })
    .catch(err => {
      if (err.response) {
        auctionsResponse = [StatusCodes.NOT_FOUND];
      }
    });
  return auctionsResponse;
}

export async function getFx(code) {
  const headers = {
    'Content-Type': 'text/html',
  };
  let exchangeResponse;
  const exchangeHref = '/get_fx';
  await corplineApi
    .get(exchangeHref, {
      params: {
        currency_symbol: code,
      },
    })
    .then(async response => {
      exchangeResponse = [StatusCodes.OK, response.data];
    })
    .catch(err => {
      if (err.response) {
        exchangeResponse = [StatusCodes.NOT_FOUND];
      }
    });
  return exchangeResponse;
}

async function headers() {
  await getCSRF();
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  return { withCredentials: true, headers: headers };
}

export async function getUserStarred(userId) {
  const url = '/get_user_starred/' + userId;
  let starredResponse;
  await corplineApi
    .get(url)
    .then(async response => {
      starredResponse = [StatusCodes.OK, response.data];
    })
    .catch(err => {
      if (err.response) {
        starredResponse = [StatusCodes.NOT_FOUND];
      }
    });
  return starredResponse;
}

export async function addUserStarred(userId, vin, auctionType) {
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  const url = '/save_user_starred';
  let starredResponse;
  await corplineApi
    .post(url, { user_id: userId, vin: vin, auction_type: auctionType }, { withCredentials: true, headers })
    .then(response => {
      this.starredVehicles = response;
    })
    .catch(err => {
      if (err.response) {
        starredResponse = [StatusCodes.NOT_FOUND];
      }
    });
  return starredResponse;
}

export async function deleteUserStarred(userId, vin) {
  const starredResponse: any = '';
  const url = '/delete_user_starred/' + userId + '/' + vin;
  await corplineApi
    .delete(url, await headers())
    .then(response => {
      return response;
    })
    .catch(err => {
      if (err.response) {
        return starredResponse;
      }
    });
  return starredResponse;
}

export async function saveClient(data) {
  let r = null;
  await corplineApi
    .post('/save_client', data, {
      headers: {
        'Content-Type': 'multipart/form-data;boundary=63c5979328c44e2c869349443a94200e',
      },
    })
    .then(response => {
      r = response;
      return response;
    })
    .catch(err => {
      if (err.response) {
        return r;
      }
    });
  return r;
}

export async function getClient(userId) {
  const url = '/get_client/' + userId;
  let r = null;
  await corplineApi
    .get(url)
    .then(async response => {
      r = response.data;
    })
    .catch(err => {
      if (err.response) {
        r = StatusCodes.NOT_FOUND;
      }
    });
  return r;
}

export async function getAllUsers() {
  let usersResponse;
  await corplineApi
    .get('/users')
    .then(async response => {
      usersResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        usersResponse = StatusCodes.NOT_FOUND;
      }
    });
  return usersResponse;
}

export async function saveOrder(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let ordersResponse;
  await corplineApi
    .post('/save_order', params, { withCredentials: true, headers: headers })
    .then(response => {
      ordersResponse = response.data;
      ordersResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        ordersResponse = err.response.status;
        ordersResponse.status = StatusCodes.INTERNAL_SERVER_ERROR;
      }
    });
  return ordersResponse;
}

export async function updateOrder(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let ordersResponse;
  await corplineApi
    .put('/save_order', params, { withCredentials: true, headers: headers })
    .then(response => {
      ordersResponse = response.data;
      ordersResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        ordersResponse = { success: 'false' };
        ordersResponse.status = StatusCodes.INTERNAL_SERVER_ERROR;
      }
    });
  return ordersResponse;
}

function getStatusLabel(id) {
  const tmpObject = consts.statusesList.filter(function (el) {
    return el.value === id;
  });
  return tmpObject[0].text;
}

function getStringDate(stringDate) {
  const tmpDate = new Date(stringDate);
  const tmpMonth = tmpDate.getMonth() + 1 < 10 ? '0' + (tmpDate.getMonth() + 1) : tmpDate.getMonth() + 1;
  const tmpMinutes = tmpDate.getMinutes() < 10 ? '0' + tmpDate.getMinutes() : tmpDate.getMinutes();
  return (
    tmpDate.getFullYear() +
    '-' +
    tmpMonth +
    '-' +
    ('0' + tmpDate.getDate()).slice(-2) +
    ' ' +
    tmpDate.getHours() +
    ':' +
    tmpMinutes
  );
}
function getAuctionTypeLabel(id) {
  const tmpObject = consts.auctionTypes.filter(function (el) {
    return el.value === id;
  });
  return tmpObject[0].text;
}
function getCustomAgencyLabel(id) {
  const tmpObject = consts.customAgencies.filter(function (el) {
    return el.value == id;
  });
  return tmpObject[0].text;
}
async function getClientName(id) {
  const clientResponse = await getClient(id);
  if (clientResponse !== StatusCodes.NOT_FOUND) {
    return clientResponse.client.contact.name ? clientResponse.client.contact.name : '';
  }
  return '';
}
async function modifyOrders(orders) {
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    order.status_text = getStatusLabel(order.status);
    order.user_name = order.user.first_name + ' ' + order.user.last_name;
    // order.user_name = await getClientName(order.user_id);
    order.create_date_text = getStringDate(order.create_date_time);
    order.changed_date_text = getStringDate(order.changed_date_time);
  }
  return orders;
}

export async function getOrders() {
  let ordersResponse;
  await corplineApi
    .get('/get_orders')
    .then(async response => {
      const orders = response.data.orders;
      ordersResponse = response.data;
      ordersResponse.orders = await modifyOrders(orders);
      ordersResponse.orders.reverse();
      ordersResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        ordersResponse = StatusCodes.NOT_FOUND;
      }
    });
  return ordersResponse;
}
export async function getModelFilter(id, tableName, fieldName = 'id') {
  const urlPath = '/get_model_filter/' + tableName + '?' + fieldName + '=' + id;
  let modelResponse;
  await corplineApi
    .get(urlPath)
    .then(async response => {
      modelResponse = response.data;
    })
    .catch(err => {
      if (err.response) {
        modelResponse = StatusCodes.NOT_FOUND;
      }
    });
  return modelResponse;
}
async function modifyOrder(order) {
  order.status_text = getStatusLabel(order.status);
  order.auction_type_text = getAuctionTypeLabel(order.auction_type);
  order.custom_agency_text = getCustomAgencyLabel(order.custom_agency);
  order.user_name = await getClientName(order.user_id);
  order.create_date_text = getStringDate(order.create_date_time);
  order.changed_date_text = getStringDate(order.changed_date_time);
  return order;
}
export async function getOrder(id) {
  let orderData;
  const modelFilterResponse = await getModelFilter(id, 'Order');
  if (modelFilterResponse !== StatusCodes.NOT_FOUND) {
    orderData = modelFilterResponse;
    if (modelFilterResponse.success) {
      if (modelFilterResponse.result) {
        orderData = await modifyOrder(modelFilterResponse.result[0]);
        return orderData;
      }
    }
  }
  return null;
}
export async function saveTransaction(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let transactionResponse;
  await corplineApi
    .post('/save_transaction', params, { withCredentials: true, headers: headers })
    .then(response => {
      transactionResponse = response.data;
      transactionResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        transactionResponse = { status: StatusCodes.INTERNAL_SERVER_ERROR };
      }
    });
  return transactionResponse;
}
export async function updateTransaction(params) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let transactionResponse;
  await corplineApi
    .put('/save_transaction', params, { withCredentials: true, headers: headers })
    .then(response => {
      transactionResponse = response.data;
      transactionResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        transactionResponse = { status: StatusCodes.INTERNAL_SERVER_ERROR };
      }
    });
  return transactionResponse;
}
async function modifyUserTransactions(userTransactions) {
  for (let i = 0; i < userTransactions.length; i++) {
    const order = userTransactions[i];
    order.tran_date_time_text = getStringDate(order.tran_date_time);
  }
  return userTransactions;
}
export async function getUserTransactions(userId) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let transactionsResponse;

  await corplineApi
    .get('/get_user_transactions/' + userId)
    .then(async response => {
      const userTransactions = response.data.user_transactions;
      transactionsResponse = response.data;
      transactionsResponse.user_transactions = await modifyUserTransactions(userTransactions);
      transactionsResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        transactionsResponse = { status: err.response.status };
      }
    });
  return transactionsResponse;
}
async function modifyUserOrders(userOrders) {
  for (let i = 0; i < userOrders.length; i++) {
    const order = userOrders[i];
    order.status_text = getStatusLabel(order.status);
    order.create_date_time_text = getStringDate(order.create_date_time);
  }
  return userOrders;
}
export async function getUserOrders(userId) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let ordersResponse;

  await corplineApi
    .get('/get_user_orders/' + userId)
    .then(async response => {
      const userOrders = response.data.user_orders;
      ordersResponse = response.data;
      ordersResponse.user_orders = await modifyUserOrders(userOrders);
      ordersResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        ordersResponse = { status: err.response.status };
      }
    });
  return ordersResponse;
}
export async function setMarginCall(params) {
  await getCSRF();
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  let marginResponse;
  await corplineApi
    .put('/set_margin_call', params, { withCredentials: true, headers: headers })
    .then(response => {
      marginResponse = response.data;
      marginResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        marginResponse = { status: StatusCodes.INTERNAL_SERVER_ERROR };
      }
    });
  return marginResponse;
}
export async function deleteTransaction(id) {
  let transactionResponse: any = '';
  const url = '/delete_transaction/' + id;
  await corplineApi
    .delete(url, await headers())
    .then(response => {
      transactionResponse = response.data;
      transactionResponse.status = StatusCodes.OK;
    })
    .catch(err => {
      if (err.response) {
        transactionResponse = { status: StatusCodes.NOT_FOUND };
      }
    });
  return transactionResponse;
}
export async function sendEmail(params) {
  await getCSRF();
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': auth.csrf,
  };
  let apiResponse;
  await corplineApi
    .post('/send_email', params, { withCredentials: true, headers: headers })
    .then(response => {
      apiResponse = StatusCodes.OK;
    })
    .catch(function (error) {
      if (error.response) {
        apiResponse = error.response.status;
      }
    });
  return apiResponse;
}
export async function bidBuyNowVehicle(type = 'bid', params, requestMethod = 'post') {
  let status = consts.OrderStatuses.COMISSIONED;
  let clientSubject = 'Potwierdzenie zlecenia licytacji';
  let adminSubject = 'Nowe zlecenie licytacji';
  let clientTemplate = 'client_bidding_order_email.html';
  let adminTemplate = 'admin_bidding_order_email.html';
  if (type === 'buy_now') {
    status = consts.OrderStatuses.COMISSIONED_FOR_PURCHASE;
    clientSubject = 'Potwierdzenie zlecenia zakupu';
    adminSubject = 'Nowe zlecenie zakupu';
    clientTemplate = 'client_buy_now_email.html';
    adminTemplate = 'admin_buy_now_email.html';
  }
  const now = new Date();
  const orderParams = {
    make: params.make,
    vin: params.vin,
    user_id: params.user_id,
    offer_number: params.id,
    auction_type: params.auction_type,
    amount: params.amount,
    currency: params.amount_currency,
    status: status,
    custom_agency: consts.customAgenciesValues.DEFAULT,
  };
  const clientParams = {
    user_id: params.user_id,
    subject: clientSubject,
    template_html: clientTemplate,
    imgSrc: params.imgSrc,
    make: params.make,
    amount: params.amount,
    amount_currency: params.amount_currency,
  };
  const adminParams = {
    user_id: consts.ADMIN_USER_ID,
    subject: adminSubject,
    template_html: adminTemplate,
    create_date_time: now.toUTCString().toString(),
    imgSrc: params.imgSrc,
    make: params.make,
    vin: params.vin,
    id: params.id,
    auction_type_text: params.auction_type_text,
    amount: params.amount,
    amount_currency: params.amount_currency,
    user_first_name: params.user_first_name,
    user_last_name: params.user_last_name,
    email: params.email,
  };
  let orderResponse;
  if (requestMethod == 'put') {
    orderResponse = await updateOrder(orderParams);
  } else {
    orderResponse = await saveOrder(orderParams);
  }
  if (orderResponse.status === consts.StatusCodes.OK) {
    const clientResponse = await sendEmail(clientParams);
    const adminResponse = await sendEmail(adminParams);
    if (clientResponse === consts.StatusCodes.OK && adminResponse === consts.StatusCodes.OK) {
      return consts.StatusCodes.OK;
    }
  }
  return consts.StatusCodes.INTERNAL_SERVER_ERROR;
}
