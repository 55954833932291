







import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import * as consts from '@/consts';

@Component({
	components: {},
})
export default class Odometer extends Vue {
	@Prop({ type: [String, Number], required: false }) odometerMetric;
	@Prop({ type: String, required: false }) type;

  @Watch('odometerMetric')
  odometerMetricChange(val, oldVal) {
      this.odometerMetric = val;
      this.getOdometerImperial();
  }

	getOdometerImperial() {
		return Math.ceil(this.odometerMetric * consts.ODOMETER_MULTIPLIER);
	}
}
