




















import {Component, Emit, Vue} from "vue-property-decorator";
import ActiveFilter from "@/components/dummy/ActiveFilter.vue";
import * as consts from '../../consts';

@Component({
  components: {
    ActiveFilter
  }
})
export default class ActiveFilters extends Vue{
  activeFilters = [];
  showModule = true;
  dataFieldsArray = consts.filtersDataFieldsArray;

  mapLabelsToFiltersFields(activeFilters){
    const tempActiveFilters = [];
    for (const i in activeFilters){
      const currentFilter = activeFilters[i];
      const currentFilterKey = activeFilters[i][0];
      // change array to one string
      if(Array.isArray(activeFilters[i][1])){
        activeFilters[i][1] = (activeFilters[i][1] as string[]).join(', ');
      }
      for (let i = 0, l = this.dataFieldsArray.length; i < l; i++) {
        if (this.dataFieldsArray[i].key === currentFilterKey) {
          currentFilter[2] = this.dataFieldsArray[i].before;
          currentFilter[3] = this.dataFieldsArray[i].after;
        }
      }
      tempActiveFilters.push(currentFilter);
    }
    return tempActiveFilters;
  }

  @Emit("update-auctions")
  async removeFilter(dataName) {
    const query = Object.assign({}, this.$route.query);
    delete query[dataName];
    await this.$router.replace({query});
    return dataName;
  }

  @Emit("disable-filters")
  async disableFilters() {
    const preservedKeys = ['pageSize', 'sortField', 'sortDir'];

    const currentQuery = { ...this.$route.query };
    Object.keys(currentQuery).forEach(key => {
      if (!preservedKeys.includes(key)) {
        delete currentQuery[key];
      }
    });

    await this.$router.replace({ query: currentQuery });
    this.showModule = false;
    this.activeFilters = [];
    return null;
  }

  setActiveFilters(query){
    for (const [key, value] of Object.entries(query)) {
      if(key !== 'pageSize' && key !== 'sortField' && key !== 'sortDir' ){
      this.activeFilters.push([key, value]);
      }
    }
    this.showModule = this.activeFilters.length ? true : false;

    this.mapLabelsToFiltersFields(this.activeFilters);
  }
  mounted(){
    const routeQuery = this.$route.query;
    this.setActiveFilters(routeQuery);
  }
}
