


























import * as API from '../../store/api';
import auth from '../../store/modules/auth';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import PasswordResetFormPart1 from "@/components/spec/PasswordResetFormPart1.vue";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    PasswordResetFormPart1
  },
})
export default class ModalPasswordResetPart1 extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: "default-text" }) messageClass;

  size = 'sm';
  showForm = true;
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};

  passwordReset1ResponseInfo = {
    show: false,
    status: 0,
    message: '',
  };

  passwordResetPart1Form = {
    email: '',
  };

  showModal(params = {}) {
    this.show = true;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  @Emit("reset-password-part-1-success")
  passwordResetPart1Success(){
    return this.passwordResetPart1Form;
  }

  @Emit("cancel")
  cancel(){
    return false;
  }

  async resetRequest(passwordResetPart1Form) {
    this.passwordResetPart1Form = passwordResetPart1Form
    console.log(this.passwordResetPart1Form.email);

    const response = await API.reset(this.passwordResetPart1Form.email);
    switch(response.status){
      case StatusCodes.HTTP_SUCCESS:
        console.log('success! ' + response);
        this.passwordResetPart1Success();
        break;
      case StatusCodes.USER_NOT_EXISTS_OR_WRONG_PASSWORD:
        console.log('Podany adres e-mail nie jest powiązany z żadnym kontem użytkownika Corpline. Wpisz ponownie poprawny adres. ' + response);
        this.passwordReset1ResponseInfo.show = true;
        this.passwordReset1ResponseInfo.status = StatusCodes.USER_NOT_EXISTS_OR_WRONG_PASSWORD;
        this.passwordReset1ResponseInfo.message = 'Podany adres e-mail nie jest powiązany z żadnym kontem użytkownika Corpline. Wpisz ponownie poprawny adres. \n';
        break;
      default:
        console.log('BLAD ' + response);
        this.passwordReset1ResponseInfo.show = true;
        this.passwordReset1ResponseInfo.status = -1;
        this.passwordReset1ResponseInfo.message = 'Coś poszło nie tak. Proszę spróbować ponownie.';
    }
  }
}
