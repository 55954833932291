

















































































import {Component, Vue} from 'vue-property-decorator';
import AuctionSearch from "@/components/spec/AuctionSearch.vue";
import AuctionTile from "@/components/dummy/AuctionTile.vue";
import FormSelect from "@/components/dummy/FormSelect.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import * as API from "@/store/api";
import * as types from "@/types";
import PaginationLite from "@/components/dummy/PaginationLite.vue";
import PaginationLarge from "@/components/dummy/PaginationLarge.vue";
@Component({
  components: {
    PaginationLarge,
    PaginationLite,
    AuctionSearch,
    AuctionTile,
    FormSelect,
    PageHeader,
  },
})
export default class AuctionsCopart extends Vue {
  pageName = 'Licytacje Copart';
  private isLoggedIn = false;
  private userStarredVehicles = [];
  private showList = false;

  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]

  numberOfItemsOptions = [
    { value: 12, text: '12' },
    { value: 24, text: '24' },
    { value: 32, text: '32' },
    { value: 64, text: '64' },
  ]

  sortOptions = [
    { value: {"field": "high_bid", "dir": "DESC"}, text: 'Cena malejąco' },
    { value: {"field": "high_bid", "dir": "ASC"}, text: 'Cena rosnąco' },
    { value: {"field": "create_datetime", "dir": "DESC"}, text: 'Data dodania malejąco' },
    { value: {"field": "create_datetime", "dir": "ASC"}, text: 'Data dodania rosnąco' },
    { value: {"field": "odometer", "dir": "DESC"}, text: 'Przebieg malejąco' },
    { value: {"field": "odometer", "dir": "ASC"}, text: 'Przebieg rosnąco' },
    { value: {"field": "year", "dir": "DESC"}, text: 'Rok produkcji malejąco' },
    { value: {"field": "year", "dir": "ASC"}, text: 'Rok produkcji rosnąco' },
    { value: {"field": "sale_date", "dir": "DESC"}, text: 'Data licytacji malejąco' },
    { value: {"field": "sale_date", "dir": "ASC"}, text: 'Data licytacji rosnąco' },
  ]

  // pagination
  page = 1;
  selectedPerPageIndex = null;
  perPage = this.numberOfItemsOptions[0].value;
  selectedSortIndex = null;
  sortVal = { ...this.sortOptions[0].value };
  auctionsParameter = {};
  loading = true;
  data = [];

  pageCount = 1;

  get itemsForList() {
    if(this.showList){
      for (let i = 0; i< this.data.length; i++){
        const currentData = this.data[i];
        if(Object.prototype.hasOwnProperty.call(currentData, 'VIN')){
          if(currentData.VIN){
            let tmpFilteredStarred = this.userStarredVehicles;
            tmpFilteredStarred = tmpFilteredStarred.filter(starred => starred.vin === currentData.VIN);
            if(tmpFilteredStarred.length){
              currentData["is_watched"] = 1;
            }
          }
        }
      }
    }
    return this.data;
  }

  async numberOfItemsUpdate(value) {
    if (this.perPage !== value) {
      this.perPage = value;
      const query = { ...this.$route.query };
      query.pageSize = String(this.perPage);
      await this.$router.replace({
        path: '/licytacje-copart/1',
        query: { ...query }
      });
      const routeQuery = JSON.stringify(this.$route.query);
      await this.getAuctions(routeQuery);
    }
  }

  async sortUpdate(value) {
    if(value != undefined){
      if (JSON.stringify(this.sortVal) !== JSON.stringify(value)) {
        this.sortVal.field = value.field;
        this.sortVal.dir = value.dir;
        this.page = 1;
        const query = {...this.$route.query};
        query.sortField = String(this.sortVal.field);
        query.sortDir = String(this.sortVal.dir);
        await this.$router.replace({
          path: '/licytacje-copart/1',
          query: {...query}
        });
        const routeQuery = JSON.stringify(this.$route.query);
        await this.getAuctions(routeQuery);
      }
    }
  }

  updateAuctions(){
    if(this.page !== 1){
      this.page = 1;
    }
    const routeQuery = JSON.stringify(this.$route.query);
    this.getAuctions(routeQuery);
  }
  getCurrentPage(){
    if(typeof this.$route.params !== 'undefined'){
      return this.$route.params.page ? parseInt(this.$route.params.page): 1;
    }
    return 1;
  }
  async getAuctions(routeQuery?) {
    window.scrollTo({top: 0,left: 0, behavior: "smooth"})
    this.loading = true;
    this.auctionsParameter =
        {
          "page" : this.page,
          "pageSize" : this.perPage,
          "sortParams" : {
            "field"  : this.sortVal.field,
            "dir"    : this.sortVal.dir
          },
          "filters": {}
        };
    let filtersRouteQuery = JSON.parse(routeQuery);

    delete filtersRouteQuery.pageSize;
    delete filtersRouteQuery.sortField;
    delete filtersRouteQuery.sortDir;

    filtersRouteQuery = JSON.stringify(filtersRouteQuery);

    if(typeof filtersRouteQuery !== 'undefined'){
      this.auctionsParameter["filters"] = JSON.parse(filtersRouteQuery);
    }

    const response = await API.getAuctions(this.auctionsParameter);
    if(typeof response === "object"){
      this.data = response.objs;
      this.pageCount = response.pageCount;
    }
    await this.getUserStarred();
    this.loading = false;
  }

  get fullItemsCount(){
    // because of limits in elasticsearch we can't have more than 10000 records.
    return this.pageCount - this.perPage;
  }

  get user(): types.User {
    API.getCSRF();
    return this.$store.state.currentUser;
  }
  get userId() {
    if(this.user.id){
      this.isLoggedIn = true;
      return this.user.id;
    }else{
      return -1;
    }
  }
  async getUserStarred() {
    const userId = this.userId;
    if(userId != -1){
      const response = await API.getUserStarred(this.userId);
      if(typeof response === 'object'){
        if(response.length){
          this.userStarredVehicles = response[1].user_starred.filter(starred => starred.auction_type == 1);
          this.showList = true;
          this.itemsForList;
          return this.userStarredVehicles;
        }
      }
    }
    this.showList = true;
    return [];
  }
  async onPageChange(page) {
    this.page = page;
    const query = Object.assign({}, this.$route.query);
    await this.$router.replace({path: '/licytacje-copart/' + this.page, query: query});
    const routeQuery = JSON.stringify(this.$route.query);
    await this.getAuctions(routeQuery);
  }
  async mounted() {
    this.page = await this.getCurrentPage();
    const routeQuery = JSON.stringify(this.$route.query);
    const perPageValueFromQuery = this.$route.query.pageSize? this.$route.query.pageSize.toString(): this.numberOfItemsOptions[0].value.toString();
    this.perPage = parseInt(perPageValueFromQuery);
    this.selectedPerPageIndex = this.numberOfItemsOptions.findIndex(option => option.value === parseInt(perPageValueFromQuery));

    if(this.$route.query.sortField && this.$route.query.sortField !== 'undefined' && this.$route.query.sortDir && this.$route.query.sortDir !== 'undefined'){
      this.sortVal.field = String([... this.$route.query.sortField].join(''));
      this.sortVal.dir = String([... this.$route.query.sortDir].join(''));
    }else{
      this.sortVal = this.sortOptions[0].value;
    }
    this.selectedSortIndex = this.sortOptions.findIndex(option =>
        option.value.field === this.sortVal.field && option.value.dir === this.sortVal.dir
    );
    await this.getAuctions(routeQuery);
  }
}
