



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class FileInput extends Vue {
	@Prop({ type: String, required: false, default: 'plain' }) type;
	@Prop({ type: String, required: false, default: '' }) accept;
	@Prop({ type: String, required: false, default: '' }) id;
	@Prop({ type: String, required: false, default: '' }) url;
	@Prop({ type: String, required: false, default: 'Wybierz plik' }) browseText;
	@Prop({ type: String, required: false, default: 'Upuść tutaj' }) dropPlaceholder;
	@Prop({ type: String, required: false, default: 'Niedozwolony format' }) noDropPlaceholder;
	@Prop({ type: String, required: false, default: 'Nie wybrano pliku' }) placeholder;

	formFileClass = 'form-file-' + this.type;
	avatarFile = null;

	@Emit('image-file-change')
	formFileChange(e) {
		const file = e.target.files[0];
    const newUrl = URL.createObjectURL(file);
    return { url: newUrl, file: file };
	}
}
