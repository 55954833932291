import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { User } from '@/types';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    currentUser: new User(),
  },
  getters: {
    currentUser: state => {
      return state.currentUser as User;
    },
    isSuperUser: state => {
      return state.currentUser['is_superuser'];
    },
  },
  mutations: {
    setCurrentUser(state, user: User) {
      if(user){
        state.currentUser = user;
      }else{
        state.currentUser = new User();
      }
    },
  },
  actions: {},

  modules: {
  },
  plugins: [createPersistedState()],
});

export default store;
