




























import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class Steps extends Vue {
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: Array, required: false, default: "" }) stepsArray;
}
