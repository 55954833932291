





























































import {Component, Ref, Vue} from 'vue-property-decorator';
import AccountBasicData from "@/components/spec/AccountBasicData.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import CRMUserOrdersListTable from "@/components/spec/CRMUserOrdersListTable.vue";
import CRMUserTransactionsListTable from "@/components/spec/CRMUserTransactionsListTable.vue";
import AddEditTransactionForm from "@/components/spec/AddEditTransactionForm.vue";
import ModalAddEditTransaction from "@/components/spec/ModalAddEditTransaction.vue";
import ModalDeleteUser from "@/components/spec/ModalDeleteUser.vue";
import AccountFinancialCapacity from "@/components/spec/AccountFinancialCapacity.vue";
import EditFinancialCapacity from "@/components/spec/EditFinancialCapacity.vue";
import * as API from "@/store/api";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    EditFinancialCapacity,
    AccountFinancialCapacity,
    AccountBasicData,
    AddEditTransactionForm,
    CRMUserTransactionsListTable,
    CRMUserOrdersListTable,
    PageHeader,
    ModalDeleteUser
  },
})
export default class SingleUser extends Vue {
  @Ref() readonly AddEditTransactionModal: ModalAddEditTransaction;
  @Ref() readonly DeleteUserModal: ModalDeleteUser;
  userId = null;
  clientData = null;
  userData = null;
  pageName = 'Ustawienia konta';
  breadcrumbItems =  [
    {
      text: this.pageName,
      active: true
    },
  ]
  showDeleteUserModal(){
    this.DeleteUserModal.showModal();
  }
  async getClient() {
    let getClientResponse = await API.getClient(this.userId);
    getClientResponse = getClientResponse.client;
    if (getClientResponse !== StatusCodes.NOT_FOUND) {
      this.clientData = getClientResponse;
    }
  }
  async getUserData(){
    const modelFilterResponse = await API.getModelFilter(this.userId, 'userData', 'user_id');
    if (modelFilterResponse !== StatusCodes.NOT_FOUND) {
      this.userData = modelFilterResponse;
      if(modelFilterResponse.success){
        if(modelFilterResponse.result){
          this.userData = modelFilterResponse.result[0];
        }
      }
    }
  }
  async mounted() {
    this.userId = this.$route.params.userId? this.$route.params.userId: '';
    await this.getClient();
    await this.getUserData();
  }
}
