





















































import {Component, Vue, Emit, Prop} from 'vue-property-decorator';
import * as API from "@/store/api";
@Component({
  components: {
  },
})
export default class CRMUserOrdersListTable extends Vue {
  @Prop({required: true, default: null }) userId;
  userOrders = null;
  @Emit('show-add-order-modal')
  showAddOrderModal(){
    return true;
  }
  private async getUserOrders() {
    const response = await API.getUserOrders(this.userId);
    if (response.success) {
      this.userOrders = response.user_orders;
    }
  }

  mounted(){
    this.getUserOrders();
  }
}
