









































































import {Component, Vue} from 'vue-property-decorator';
import FileInput from "@/components/dummy/FileInput.vue";
import AccountBasicDataEdit from "@/components/spec/AccountBasicDataEdit.vue";
@Component({
  components: {
    FileInput,
    AccountBasicDataEdit
  },
})
export default class PaymentInfo extends Vue {
  defaultImageUrl = '@/assets/default-user.svg';
  changedImageUrl = null;
  imgContainerClass = 'no-selected'
  editDataVisible = false;

  imageFileChange(imageSrc){
    this.changedImageUrl = imageSrc;
    this.imgContainerClass = 'selected'
  }
  toggleDataContent(){
    this.editDataVisible = !this.editDataVisible
  }
}
