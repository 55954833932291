















































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import Accordion from "@/components/spec/Accordion.vue";
import AccountBasicData from "@/components/spec/AccountBasicData.vue";
import AccountFinancialCapacity from "@/components/spec/AccountFinancialCapacity.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
@Component({
  components: {
    Accordion,
    AccountBasicData,
    PageHeader,
    AccountFinancialCapacity
  },
})
export default class Account extends Vue {
  pageName = 'Ustawienia konta';
  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]
  loading = true;
  data = [];

  faqList = [];

}
