




























































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import * as consts from '../../consts';
import * as API from "@/store/api";

@Component({
  components: {
  },
})
export default class PurchasedTile extends Vue {
  @Prop() purchasedData;
  @Watch('purchasedData')
  purchasedDataChange(val, oldVal) {
    console.log('change');
    this.setThumbnail();
  }
  imgDefault = consts.auctionImageDefaultSource;
  thumbnail = this.imgDefault;
  goToSinglePurchasedVehicle(){
    window.scrollTo(0,0);
    this.$router.push({
      name: 'singlePurchasedVehicle',
      params: {
        obj: this.purchasedData
      },
      query: { vin: this.purchasedData.vehicle.vin }
    });
  }


  async setThumbnail(){
    let cargoloopThumbnail;
    if(Object.prototype.hasOwnProperty.call(this.purchasedData, 'auctionPhotos')){
      cargoloopThumbnail = this.purchasedData.auctionPhotos;
    }else if(Object.prototype.hasOwnProperty.call(this.purchasedData, 'terminalPhotos')){
      cargoloopThumbnail = this.purchasedData.terminalPhotos;
    }else if(Object.prototype.hasOwnProperty.call(this.purchasedData, 'loadingPhotos')){
      cargoloopThumbnail = this.purchasedData.loadingPhotos;
    }else if(Object.prototype.hasOwnProperty.call(this.purchasedData, 'unloadingPhotos')){
      cargoloopThumbnail = this.purchasedData.unloadingPhotos;
    }
    if(cargoloopThumbnail !== null) {
      if(Object.prototype.hasOwnProperty.call(cargoloopThumbnail, 'image')){
        cargoloopThumbnail = cargoloopThumbnail.image[0];
        if(cargoloopThumbnail !== null) {
          if(Object.prototype.hasOwnProperty.call(cargoloopThumbnail, 'URL')){
            this.thumbnail = cargoloopThumbnail.URL;
          }
        }
      }
    }
  }

  setPurchaseDate(){
    const purchaseDate = new Date(this.purchasedData.purchase.purchaseDate);
    const purchaseString = ('0' + purchaseDate.getDate()).slice(-2) + '.'
        + ('0' + (purchaseDate.getMonth()+1)).slice(-2) + '.'
        + purchaseDate.getFullYear();
    return purchaseString;
  }
  mounted(){
    this.setThumbnail();
  }
}
