


















import {Component, Vue} from 'vue-property-decorator';
import PageHeader from "@/components/dummy/PageHeader.vue";
import UsersListTable from "@/components/spec/UsersListTable.vue";
import * as API from '../../store/api';

@Component({
  components: {
    UsersListTable,
    PageHeader,
  },
})
export default class UserList extends Vue {

  pageName = 'Lista użytkowników';
  usersList = null;
  loading = true;
  breadcrumbItems =  [
    {
      text: this.pageName,
      active: true
    }
  ];
  async getUserList() {
    this.loading = true;
    const response = await API.getAllUsers();
    if(response.success){
      this.usersList = response.users;
    }
    this.loading = false;
  }
  created(){
    this.getUserList();
  }
}
