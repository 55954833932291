
























































































































import {Vue, Component, Emit, Prop} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import FormSelect from "@/components/dummy/FormSelect.vue";
import * as consts from '../../consts';
import DateInput from "@/components/dummy/DateInput.vue";

@Component({
  components: {
    DateInput,
    FormSelect,
  },
  mixins: [validationMixin],
  validations: {
    transactionData: {
      title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      account: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      date: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      amount: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      transactionType: {
        required,
      },
    }
  },
})
export default class AddEditTransactionForm extends Vue{
  @Prop({ type: Boolean, required: false, default: false }) isEditView;
  @Prop({ required: false, default: null }) transaction;
  loading = false;
  showError = false;
  public transactionType = 1;
  transactionData = {
    title: null,
    account: null,
    date: null,
    amount: null,
    currency: null,
    transactionType: this.transactionType,
  }
  capacityCurrencies = consts.capacityCurrencies;
  selectedCurrency = this.capacityCurrencies[0].value;
  defaultDateValue = null;
  usersList = null;

  selectTransactionType(clickedAccountType){
    this.transactionData.transactionType = clickedAccountType
    this.transactionType = clickedAccountType;
  }
  currencyChange(value){
    this.transactionData['currency'] = value;
  }
  dateChange(value){
    console.log('dateChange');
    console.log(value);
    this.transactionData['date'] = value;
  }
  setDefaultValues(){
    console.log(this.transaction);
    this.transactionData = {
      title: this.transaction.title,
      account: this.transaction.account,
      date: this.transaction.tran_date_time,
      amount: this.transaction.amount,
      currency: this.transaction.amount_currency,
      transactionType: this.transaction.tran_type,
    }
    this.selectedCurrency = this.transactionData.currency;
    this.selectTransactionType(this.transaction.tran_type);
    this.defaultDateValue = this.transactionData.date;
    console.log(this.defaultDateValue);
  }
  async mounted(){
    console.log('this.transaction');
    console.log(this.transaction);
    if(this.isEditView && this.transaction){
      this.setDefaultValues();
    }
  }
  @Emit("transaction-form-approved")
  transactionSubmit(event){
    event.preventDefault()
    if(!this.isEditView){
      return this.transactionData;
    }
    const tmpTransaction = this.transactionData;
    tmpTransaction['id'] = this.transaction.id;
    return tmpTransaction;
  }
}
