





































































































































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import Countdown from "@/components/dummy/Countdown.vue";
import QuantityInput from "@/components/dummy/QuantityInput.vue";
import * as API from "@/store/api";
import ModalBidConfirmation from "@/components/spec/ModalBidConfirmation.vue";
import ModalBuyNowConfirmation from "@/components/spec/ModalBuyNowConfirmation.vue";
@Component({
  components: {
    ModalBidConfirmation,
    ModalBuyNowConfirmation,
    QuantityInput,
    Countdown
  },
})
export default class AuctionBidDetails extends Vue {
  @Prop() auctionData;
  @Prop({ type: String, required: false, default: "copart"}) type;
  @Prop({ type: Boolean, required: false, default: false}) offerExistsInUserBids;
  @Ref() readonly BidConfirmationModal: ModalBidConfirmation;
  @Ref() readonly BuyNowConfirmationModal: ModalBuyNowConfirmation;
  isLoggedIn = false;
  price = null;
  priceToBid = null;
  thumbnail = null;
  isTimeLeft = true;
  countDownDate;
  mutableOfferExistsInUserBids = this.offerExistsInUserBids;
  setCountDown () {
    this.countDownDate = this.dateFromString;
    if (this.countDownDate !== -1) {
      const currentDate = new Date();
      let currentDateTime = currentDate.getTime();
      const diffInTime = this.countDownDate.getTime() - currentDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);

      if (diffInDays <= 1) {
        this.countDownDate = this.countDownDate.getTime();
        currentDateTime = new Date().getTime();
        const timeLeft = this.countDownDate - currentDateTime;
        if (timeLeft <= 0) {
          this.isTimeLeft = false;
        }
      }
    }
  }
  get dateFromString(){
    if(this.type === 'copart'){
      const dateYear = parseInt(this.auctionData.sale_date.substring(0, 4));
      const dateMonth = parseInt(this.auctionData.sale_date.substring(4, 6));
      const dateDay = parseInt(this.auctionData.sale_date.substring(6));
      const dateHours = this.auctionData.sale_time.substring(0, 2);
      const dateMinutes = this.auctionData.sale_time.substring(2);
      const tmpDateString = dateYear + '-' + dateMonth + '-' +  dateDay + ' ' + dateHours + ':' + dateMinutes + ' ' + this.auctionData.time_zone;
      const tmpDate = new Date(tmpDateString);
      if(tmpDate instanceof Date && !isNaN(tmpDate.getTime())){
        return tmpDate;
      } else{
        return -1;
      }
    }else{
      const tmpDate = new Date(this.auctionData.utc_close_date);
      if(tmpDate instanceof Date && !isNaN(tmpDate.getTime())){
        return tmpDate;
      } else{
        return -1;
      }
    }
  }
  prepareData(){
    if(this.type === 'copart'){
      this.price = parseFloat(this.auctionData.high_bid);
      this.thumbnail = this.auctionData.image_thumbnail;
      this.setCountDown()
    }else{
      this.thumbnail = this.auctionData.img_src;
      this.price = this.auctionData.current_bid;
      this.setCountDown()
    }
    this.priceToBid = this.price + 100;
  }
  checkIsLoggedIn(){
    API.getCSRF();
    if(this.$store.state.currentUser.id){
      this.isLoggedIn = true;
      return true;
    }
    return false;
  }

  bidVehicle(){
    this.BidConfirmationModal.showModal();
  }
  buyNowVehicle(){
    this.BuyNowConfirmationModal.showModal();
  }
  updateBidPrice(price){
    this.priceToBid = price;
  }
  updateOfferExistance(value){
    this.mutableOfferExistsInUserBids = value;
  }
  mounted(){
    this.checkIsLoggedIn();
    this.prepareData();
  }
}
