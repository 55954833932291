























import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import { ModalEvents } from "../../../src/consts";

@Component
export default class ModalInfo extends Vue {
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) message;
  @Prop({ type: String, required: false, default: "default-text" }) messageClass;
  @Prop({ type: String, required: false, default: "h3-text" }) titleClass;
  @Prop({ type: String, required: false, default: "sm" }) size;

  private show = false;
  private events: [ModalEvents.OK_EVENT, ModalEvents.CANCEL_EVENT];

  // ?
  @Emit()
  modalClose() {
    return 1;
  }

  showModal() {
    this.show = true;
  }
  saveModal() {
    this.$emit(ModalEvents.OK_EVENT);
    this.show = false;
  }
  @Emit("hide-modal-info")
  hideModal() {
    this.$emit(ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
