




















































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import FinancialCapacityCalculator from "@/components/spec/FinancialCapacityCalculator.vue";
@Component({
  components:{
    FinancialCapacityCalculator
  }
})
export default class AccountDetailsPart3Form extends Vue{
  calcInput = 0;
  calcOutput = 0;

  @Emit("account-details-part-3-submitted")
  accountDetailsPart3Submit(event){
    event.preventDefault()
    return true;
  }

  @Emit("account-details-part-3-back")
  accountDetailsPart3Back(event){
    return true;
  }

  onPaste (evt) {
    evt.preventDefault();
  }

  isNumber (evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed: string = evt.key;
    console.log(keyPressed);
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault()
    }
  }

  get calculatedCarValue(){
    this.calcOutput = this.calcInput / 4;
    return this.calcOutput;
  }

}
