









import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class ActiveFilter extends Vue{
  @Prop({ type: String, required: true}) dataName;
  @Prop({ type: String, required: false}) dataValue;
  @Prop({ type: String, required: false}) preText;
  @Prop({ type: String, required: false}) unit;
  show = true;
  @Emit("remove-filter")
  removeFilter(){
    this.show = !this.show;
    return this.dataName;
  }

}
