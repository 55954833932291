


















































import {Component, Vue} from 'vue-property-decorator';
import Accordion from "@/components/spec/Accordion.vue";
import AuctionBidDetails from "@/components/dummy/AuctionBidDetails.vue";
import AuctionDetails from "@/components/spec/AuctionDetails.vue";
import AuctionReportsOrder from "@/components/spec/AuctionReportsOrder.vue";
import FormSelect from "@/components/dummy/FormSelect.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import WatchedTile from "@/components/dummy/WatchedTile.vue";
import {StatusCodes} from "@/consts";
import * as API from "@/store/api";
import * as consts from "@/consts";
import * as helpers from "@/helpers";
import * as types from "@/types";
@Component({
  components: {
    AuctionBidDetails,
    AuctionDetails,
    WatchedTile,
    AuctionReportsOrder,
    Accordion,
    FormSelect,
    PageHeader,
  },
})
export default class WatchedListOfVehicles extends Vue {
  pageName = 'Obserwowane';
  loading = true;

  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]
  private faqList = [];

  private isLoggedIn = false;
  private hasStarred = false;
  private starredVehicles;
  get user(): types.User {
    API.getCSRF();
    console.log('GetUSER', helpers.currentUser);
    console.log(this.$store.state.currentUser);
    if(this.$store.state.currentUser.id){
      console.log('zalgoowany');
    }else{
      console.log('niezalgoowany');
    }
    return this.$store.state.currentUser;
  }
  get userId() {
    console.log('this.user.id:');
    console.log(this.user.id);
    if(this.user.id){
      this.isLoggedIn = true;
      console.log('tak');
      console.log(this.user.id);
      return this.user.id;
    }else{
      console.log('nie');
      return -1;
    }
  }
  get itemsForList() {
    console.log('itemsForList');
    console.log(this.starredVehicles);
    return this.starredVehicles;
  }
  async getUserStarred() {
    this.loading = true;

    const response = await API.getUserStarred(this.userId);
    console.log('getUserStarredresponse');
    console.log(response);
    if(typeof response === "object"){
      if(response[0] === StatusCodes.OK){
        console.log(response[1].success);
        if(response[1].success){
          this.starredVehicles = response[1].user_starred;
          this.loading = false;
          if(this.starredVehicles.length){
            this.hasStarred = true;
          }
        }
      }
    }
  }
  // async addUserStarred() {
  //   const response = await API.addUserStarred(this.userId, 'WUARL48H15K901070', 2);
  //   if(typeof response === "object"){
  //     if(response[0] === StatusCodes.OK){
  //       if(response[1].success === 'true'){
  //         this.starredVehicles = response[1].user;
  //       }
  //     }
  //   }
  // }


  mounted(){
    this.faqList = consts.defaultHelpList;
    this.getUserStarred();
  }
}
