<template>
  <div class="language-switcher-container">
    <div class="language-switcher">
      <select name="language-switcher-select" class="language-switcher-select">
        <option value="pl">PL</option>
        <option value="en">EN</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitcherElement"
}
</script>

<style scoped lang="scss">
.language-switcher-container{
  padding: 0 1.6rem 0 1.9rem;
  .language-switcher{
    position: relative;
    .language-switcher-select{
      padding-right: 1.8rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      @extend %default-text-3;
      color: $color1;
      background-image: url("~@/assets/select-arrow.svg");
      background-color: transparent;
      background-size: 1.6rem;
      background-repeat: no-repeat;
      background-position: right center;
      @include breakpoint($container-w1){
        font-size: $px-14-0;
        background-size: 1.4rem;
      }
    }
  }
}
</style>
