








































































































































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Odometer from "@/components/dummy/Odometer.vue";
import * as consts from '../../consts';
import * as API from "@/store/api";

@Component({
  components: {
    Odometer
  },
})
export default class WatchedTile extends Vue {
  @Prop({required: true, default: '' }) watchedData;
  @Prop({required: true, default: '' }) userId;
  @Watch('watchedData')

  private data;
  private isVisible = false;
  private isWatched = true;
  private auctionType : any = 0;
  watchedDataChange(val, oldVal) {
    console.log('change');
    this.setThumbnailCopart();
  }
  imgDefault = consts.auctionImageDefaultSource;
  thumbnail = this.imgDefault;
  goToSingleAuction(){
    window.scrollTo(0,0);
    this.$router.push({
      name: 'singlePurchasedVehicle',
      params: {
        obj: this.watchedData
      },
      query: { vin: this.watchedData.vehicle.vin }
    });
  }
  async setThumbnailCopart(){
    const last  = '/' + this.data.image_url.split("/").pop();
    const galleryJson = { "param" : last };

    const response = await API.getAuctionImages(galleryJson);
    if(typeof response === "object"){
      if(typeof response.lotImages !== 'undefined'){
        const linkObject = JSON.parse(JSON.stringify(response.lotImages[0]["link"]));
        for (const linkEl of Object.keys(linkObject)) {
          let isHdImage = null;
          let isThumbNail = null;
          let tmpUrl = null;
          isHdImage = linkObject[linkEl]["isHdImage"];
          isThumbNail = linkObject[linkEl]["isThumbNail"];
          tmpUrl = linkObject[linkEl]["url"];
          if(!isThumbNail && isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(!isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
        }
      }else{
        this.thumbnail = 'http://' + this.data.image_thumbnail;
      }
    }else{
      console.log('BLAD  ' + response);
      this.thumbnail = 'http://' + this.data.image_thumbnail;
    }
  }
  handleUserStarred(){
    if(this.isWatched){
      this.deleteStarred();
    }else{
      this.addStarred();
    }
  }
  async addUserStarred(userId, vin, auctionType){
    await API.addUserStarred(userId, vin, auctionType);
  }
  addStarred(){
    this.addUserStarred(this.userId, this.watchedData.vin, this.auctionType);
    this.isWatched = !this.isWatched;
  }
  async deleteUserStarred(userId, vin){
    await API.deleteUserStarred(userId, vin);
  }
  deleteStarred(){
    this.deleteUserStarred(this.userId, this.watchedData.vin);
    this.isWatched = !this.isWatched;
  }
  setThumbnailIaai(){
    if(this.data.img_src.length){
      this.thumbnail = this.data.img_src;
    }
  }
  setAuctionType(){
    switch (this.watchedData.auction_type){
      case 1:
        this.auctionType = 1;
        break;
      case 2:
        this.auctionType = 2;
        break;
    }
    this.getAuction();
  }
  async getAuction() {
    let apiResponse;
    const copartAuctionsParameter = {
          "page": 1,
          "pageSize": 1,
          "sortParams": {
            "field": "sale_date",
            "dir": "DESC"
          },
          "filters": {
            "VIN": this.watchedData.vin
          }
        }
    const iaaiAuctionsParameter = {
          "page": 1,
          "pageSize": 1,
          "sortParams": {
            "field": "current_bid",
            "dir": "DESC"
          },
          "filters": {
            "vin": this.watchedData.vin
          }
        }
    switch (this.auctionType){
      case 1:
        apiResponse = await API.getAuctions(copartAuctionsParameter);
        if(typeof apiResponse === "object"){
          this.data = apiResponse.objs[0];
          if(typeof this.data !== 'undefined'){
            this.setThumbnailCopart();
            this.isVisible = true;
          }
        }else{
          console.log('BLAD  ' + apiResponse);
          this.isVisible = false;
        }
        break;
      case 2:
        apiResponse = await API.getIaaiAuctions(iaaiAuctionsParameter);
        if(typeof apiResponse === "object"){
          this.data = apiResponse.objs[0];
          if(typeof this.data !== 'undefined'){
            this.setThumbnailIaai();
            this.isVisible = true;
          }
        }else{
          console.log('BLAD  ' + apiResponse);
          this.isVisible = false;
        }
        break;
    }
  }
  mounted(){
    this.setAuctionType();
  }
}
