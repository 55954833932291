



























































































































































































































































































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import PageHeader from "@/components/dummy/PageHeader.vue";
import Gallery from "@/components/dummy/Gallery.vue";
import Table from "@/components/dummy/Table.vue";
import * as API from "@/store/api";
@Component({
  components: {
    PageHeader,
    Gallery,
    Table
  },
})
export default class SinglePurchasedVehicle extends Vue {
  pageName = null;
  purchasedData = null;
  breadcrumbItems =  []

  async getPurchasedCargoloop(){
    window.scrollTo(0,0)
    const purchasedVehicleVIN = this.$route.query.vin;
    if(purchasedVehicleVIN){
      const response = await API.getPurchasedCargoloop(purchasedVehicleVIN);
      if(typeof response === "object"){
        this.purchasedData = response;
      }
    }
  }
  getDate(date){
    const purchaseDate = new Date(date);
    const purchaseString = ('0' + purchaseDate.getDate()).slice(-2) + '.'
        + ('0' + (purchaseDate.getMonth()+1)).slice(-2) + '.'
        + purchaseDate.getFullYear();
    return purchaseString;
  }

  createListForBreadcrumbs(){
    this.breadcrumbItems =  [
      {
        text: 'Strona główna',
        to: {name: 'home'}
      },
      {
        text: 'Zakupione samochody',
        to: {name: 'purchasedVehicles'}
      },
      {
        text: this.pageName,
        active: true
      }
    ]
  }

  created(){
    if(this.$route.params.obj){
      this.purchasedData = this.$route.params.obj;
      this.pageName = this.purchasedData.vehicle.description;
      this.createListForBreadcrumbs();
    }else{
      if(this.$route.query.vin){
        this.getPurchasedCargoloop();
      }else{
        this.$router.push({name: 'purchasedVehicles'});
      }
    }
  }
}
