



























import {Component, Vue, Prop, Emit} from 'vue-property-decorator';
import * as consts from '../../consts';
import AddEditOrderForm from "@/components/spec/AddEditOrderForm.vue";
import AddEditTransactionForm from "@/components/spec/AddEditTransactionForm.vue";
import * as API from "@/store/api";
import {StatusCodes} from "../../consts";
@Component({
  components: {
    AddEditTransactionForm,
    AddEditOrderForm,
  },
})
export default class ModalAddEditTransaction extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: Boolean, required: false, default: false }) isEditView;
  @Prop({ required: false, default: null }) transaction;

  transactionResponseInfo = {
    show: null,
    success: null,
    message: null,
  };

  size = 'lg';
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  userId = null;
  showModal() {
    this.show = true;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.transactionResponseInfo = {
      show: false,
      success: false,
      message: '',
    };
    this.show = false;
  }

  @Emit('add-edit-transaction-response')
  async transactionSubmit(transactionData) {
    event.preventDefault()
    if(this.userId){
      const params = {
        "user_id": this.userId,
        "title": transactionData.title,
        "tran_date_time": transactionData.date,
        "tran_type": transactionData.transactionType,
        "amount": transactionData.amount,
        "currency": transactionData.currency,
        "account": transactionData.account,
      }
      let response = null;
      if (this.isEditView) {
        params["id"] = transactionData.id;
        response = await API.updateTransaction(params);
      } else {
        response = await API.saveTransaction(params);
      }
      switch (response.status) {
        case StatusCodes.OK: {
          this.transactionResponseInfo.show = true;
          if (response.success) {
            this.transactionResponseInfo.success = true;
            this.transactionResponseInfo.message = 'Gotowe!';
            return true;
          } else {
            this.transactionResponseInfo.success = false;
            if (response.error_code) {
              switch (response.error_code) {
                case '2':
                  this.transactionResponseInfo.message = 'Błąd! Sprawdź formularz.';
                  break;
                case '3':
                  this.transactionResponseInfo.message = 'Błąd! Nieprawidłowe parametry.';
                  break;
                default:
                  this.transactionResponseInfo.message = 'Błąd! Proszę spróbować ponownie ponownie później.';
              }
            }
          }
          break;
        }
        default: {
          this.transactionResponseInfo.show = true;
          this.transactionResponseInfo.success = false;
          this.transactionResponseInfo.message = 'Błąd! Proszę spróbować ponownie ponownie później.';
        }
      }
    }else{
      this.transactionResponseInfo.success = false;
      this.transactionResponseInfo.message = 'Błąd! Brak użytkownika w bazie.';
    }
    return false;
  }
  mounted(){
    this.userId = this.$route.params.userId? this.$route.params.userId: null;
  }
}
