























import * as API from '../../store/api';
import auth from '../../store/modules/auth';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import LoginForm from '@/components/spec/LoginForm.vue';
import {StatusCodes} from "@/consts";
import {User} from "@/types";
import store from "@/store";
@Component({
  components: {
    LoginForm,
  },
})
export default class ModalLogin extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;

  size = 'sm';
  showLoginForm = true;
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};

  loginResponseInfo = {
    show: false,
    status: 0,
    message: '',
  };

  loginForm = {
    email: '',
    password: '',
  };

  showModal(params = {}) {
    this.show = true;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  @Emit("show-registration")
  showRegistration(){
    return true;
  }

  @Emit("login-success")
  loginSuccess(){
    return StatusCodes.OK;
  }

  @Emit("forgotten-password")
  forgottenPassword(){
    return true;
  }

  async login(loginForm) {
    this.loginResponseInfo.show = false;
    this.loginForm = loginForm
    const response = await API.loginUser({
      email: this.loginForm.email,
      password: this.loginForm.password,
    });
    console.log(response);
    switch(response.code){
      case StatusCodes.OK:{
        const user = Object.assign(new User(), response.data.user);
        if (user !== undefined) {
          store.commit('setCurrentUser', user);
        }
        this.loginSuccess();
        break;
      }
      case 2: {
        this.loginResponseInfo.show = true;
        this.loginResponseInfo.status = StatusCodes.USER_NOT_EXISTS_OR_WRONG_PASSWORD;
        this.loginResponseInfo.message = 'Użytkownik nie istnieje\n';
        break;
      }
      case 3: {
        this.loginResponseInfo.show = true;
        this.loginResponseInfo.status = StatusCodes.USER_NOT_EXISTS_OR_WRONG_PASSWORD;
        this.loginResponseInfo.message = 'Sprawdź, czy adres e-mail i hasło są poprawne\n';
        break;
      }
      case 5: {
        this.loginResponseInfo.show = true;
        this.loginResponseInfo.status = StatusCodes.USER_NOT_EXISTS_OR_WRONG_PASSWORD;
        this.loginResponseInfo.message = 'Konto nie zostało aktywowane\n';
        break;
      }
      default: {
        this.loginResponseInfo.show = true;
        this.loginResponseInfo.status = -1;
        this.loginResponseInfo.message = 'Logowanie nie powiodło się. Proszę spróbować ponownie.';
      }
    }
  }
}
