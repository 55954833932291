





















































































































































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import { mixins } from 'vue-class-component';
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import * as API from "@/store/api";

@Component({
  mixins: [validationMixin],
  validations: {
    accountData: {
      contact: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        street_and_house_no: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        city: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        postal_code: {
          required,
        },
        phone: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
      },
      invoice: {
        name: {
          maxLength: maxLength(48),
        },
        nip: {
          maxLength: maxLength(48),
        },
        street_and_house_no: {
          maxLength: maxLength(48),
        },
        postal_code: {
          maxLength: maxLength(48),
        },
        city: {
          maxLength: maxLength(48),
        },
      },
      delivery: {
        name: {
          maxLength: maxLength(48),
        },
        street_and_house_no: {
          maxLength: maxLength(48),
        },
        postal_code: {
          maxLength: maxLength(48),
        },
        city: {
          maxLength: maxLength(48),
        },
        point_type: {
          maxLength: maxLength(48),
        },
        phone: {
          maxLength: maxLength(48),
        },
      },
    }
  },

})
export default class AccountDetailsPart2Form extends mixins(Vue, validationMixin){
  @Prop({ type: Number, required: false, default: '' }) accountType;
  @Prop({ required: false }) clientData;
  submitStatus = ''
  loading = false;
  showError = false;
  accountData = {
    contact: {
      name: null,
      street_and_house_no: null,
      city: null,
      postal_code: null,
      phone: null,
    },
    invoice: {
      name: null,
      nip: null,
      street_and_house_no: null,
      postal_code: null,
      city: null,
    },
    delivery: {
      name: null,
      street_and_house_no: null,
      postal_code: null,
      city: null,
      point_type: null,
      phone: null,
    },
    type: this.accountType
  }

  get userId() {
    return this.$store.state.currentUser.id;
  }

  async saveAccount(accountData) {
    this.loading = true;
    const formData = new FormData();
    formData.append('user_id', this.userId);
    formData.append('file', '');
    formData.append('client', JSON.stringify(accountData));
    const saveClientResponse = await API.saveClient(formData);
    this.loading = false;
    if(saveClientResponse === null || saveClientResponse.status !== 200){
      this.showError = true;
    }else{
      this.emitSavedForm();
    }
  }

  @Emit("account-details-part-2-submitted")
  emitSavedForm(){
    return true;
  }

  accountDetailsPart2Submit(){
    this.$v.$touch()
    if (!this.$v.$invalid) {
      this.loading = true;
      this.saveAccount(this.accountData);
    }
  }

  @Emit("account-details-part-2-back")
  accountDetailsPart2Back(event){
    return true;
  }
  fillFormWithValuesFromDB(){
    if(this.clientData){
      this.accountData = {
        contact: {
          name: this.clientData.contact.name,
          street_and_house_no: this.clientData.contact.street_and_house_no,
          city: this.clientData.contact.city,
          postal_code: this.clientData.contact.postal_code,
          phone: this.clientData.contact.phone,
        },
        invoice: {
          name: this.clientData.invoice.name,
          nip: this.clientData.invoice.nip,
          street_and_house_no: this.clientData.invoice.street_and_house_no,
          postal_code: this.clientData.invoice.postal_code,
          city: this.clientData.invoice.city,
        },
        delivery: {
          name: this.clientData.delivery.name,
          street_and_house_no: this.clientData.delivery.street_and_house_no,
          postal_code: this.clientData.delivery.postal_code,
          city: this.clientData.delivery.city,
          point_type: this.clientData.delivery.point_type,
          phone: this.clientData.delivery.phone,
        },
        type: this.accountType
      }
    }
  }
  mounted(){
    this.fillFormWithValuesFromDB();
  }
}
