






























import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';

const NOT_CHOOSEN = 100;

@Component
export default class ModalChooseTime extends Vue {
  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  hour = 0;
  hours = Array(24)
    .fill(0)
    .map((e, i) => i);
  hourMarks = [0, 3, 6, 9, 12, 15, 18, 21];
  minute = 0;
  minutes = Array(60)
    .fill(0)
    .map((e, i) => i);
  minuteMarks = [0, 10, 20, 30, 40, 50];
  time = '';

  showModal(time = '') {
    this.show = true;
    const splitted = time.split(':');
    if (splitted.length === 2) {
      this.hour = parseInt(splitted[0]);
      if (!this.hours.includes(this.hour)) {
        this.hour = 0;
      }
      this.minute = parseInt(splitted[1]);
      if (!this.minutes.includes(this.minute)) {
        this.minute = 0;
      }
    }
  }
  saveModal() {
    this.save = true;
    this.$emit(
      consts.ModalEvents.OK_EVENT,
      this.hour.toString().padStart(2, '0') + ':' + this.minute.toString().padStart(2, '0')
    );
    this.show = false;
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.show = false;
  }
}
