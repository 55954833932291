





















import {Component, Vue} from 'vue-property-decorator';
import FinancialCapacityCalculator from "@/components/spec/FinancialCapacityCalculator.vue";
import * as API from "@/store/api";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    FinancialCapacityCalculator,
  },
})
export default class AccountFinancialCapacity extends Vue {
  client = null;
  userData = null;
  get userId() {
    return this.$store.state.currentUser.id;
  }
  async getUserData(){
    const modelFilterResponse = await API.getModelFilter(this.userId, 'userData', 'user_id');
    if (modelFilterResponse !== StatusCodes.NOT_FOUND) {
      this.userData = modelFilterResponse;
      if(modelFilterResponse.success){
        if(modelFilterResponse.result){
          this.userData = modelFilterResponse.result[0];
        }
      }
    }
  }
  async mounted() {
    await this.getUserData();
  }
}
