




















import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import BackgroundImageWithContent from "@/components/dummy/BackgroundImageWithContent.vue";
import AuctionSearchLite from "@/components/spec/AuctionSearchLite.vue";
@Component({
  components: {
    BackgroundImageWithContent,
    AuctionSearchLite
  },
})
export default class BannerWithCarSearch extends Vue {
  @Prop({ type: String, required: false, default: "" }) type;
  @Prop({ type: String, required: false, default: "https://damian.uico.pl/142.Corpline/default-background-image.jpg" }) imageSrc;
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) text;
  @Prop({ type: String, required: false, default: "" }) buttonText;
  @Prop({ required: false, default: "" }) buttonRouterPath;
  @Prop({ type: String, required: false, default: "" }) buttonLink;
  @Prop({ type: String, required: false, default: "" }) buttonTarget;

}
