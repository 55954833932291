





















import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import TextSection from "@/components/dummy/TextSection.vue";
import SearchResultsSection from "@/components/dummy/SearchResultsSection.vue";
import SearchSection from "@/components/dummy/SearchSection.vue";
import * as CMS from "@/store/cms";
@Component({
  components: {
    TextSection,
    SearchResultsSection,
    SearchSection
  },
})
export default class SearchResults extends Vue {
  private showSearchResults = false;
  private loading = false;
  private searchResults = [];
  private noResults = false;
  async matchViewsToPagesFromCMS(pageID){
    const response = await CMS.matchViewsToPagesFromCMS(pageID);
    if(typeof response === "object"){
      return response;
    }else{
      return -1;
    }
  }
  async getSearchResultsFromCMS(search){
    const responseWithViews = [];
    const response = await CMS.getSearchResultsFromCMS(search);
    if(response.length){
      for (const element of response) {
        if(element.subtype === 'page'){
          const pageFields = await this.matchViewsToPagesFromCMS(element.id)
          let viewName = pageFields.template;
          viewName = viewName.replace('template-', '').replace('-page.php', '');
          element['view_name'] = viewName;
          responseWithViews.push(element);
        }
      }
      return responseWithViews;
    }else{
      return -1;
    }
  }
  async searchSubmit(searchForm){
    this.searchResults = [];
    this.showSearchResults = false;
    this.loading = true;
    const searchResults = await this.getSearchResultsFromCMS(searchForm.search);
    if(searchResults !== -1){
      this.searchResults = searchResults;
      this.showSearchResults = true;
      this.noResults = false;
    }else{
      this.noResults = true;
    }
    this.loading = false;
  }
  created(){
    //
  }
  mounted(){
    //
  }
}
