

































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import AuctionTileLite from "@/components/dummy/AuctionTileLite.vue";
import * as consts from "@/consts";
import * as API from "@/store/api";
@Component({
  components: {
    AuctionTileLite
  },
})
export default class AuctionsCarousel extends Vue {
  @Prop({ type: String, required: false, default: "Wybrane dla Ciebie" }) title;

  auctionSlides = [];
  dayForUpcomingAuction = consts.getDateInSpecificFormat(30);
  isVisible = false;
  data = [];

  get itemsForList() {
    const chunkSize = 6;
    for (let i = 0; i < this.data.length; i += chunkSize) {
      const chunk = this.data.slice(i, i + chunkSize);
      this.auctionSlides.push(chunk);
    }
    return this.auctionSlides;
  }

  async getAuctions() {
    window.scrollTo(0,0)
    const auctionsParameter =
        {
          "page": 1,
          "pageSize": 23,
          "sortParams": {
            "field": "sale_date",
            "dir": "DESC"
          },
          "filters": {
            "year__gte": "2015",
            "buy_it_now_price__gte": "5000",
            "sale_date__lte": this.dayForUpcomingAuction
          }
        }
    const response = await API.getAuctions(auctionsParameter);
    if(typeof response === "object"){
      this.data = response.objs;
      this.isVisible = true;
    }else{
      this.isVisible = false;
    }
  }

  mounted(){
    this.getAuctions();
  }
}
