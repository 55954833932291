














import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Table extends Vue {
  @Prop({ type: String, required: false}) title;
  @Prop({ type: Array, required: true}) data;


}
