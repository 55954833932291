

















































import {Component, Vue, Prop} from 'vue-property-decorator';
import * as API from "@/store/api";
@Component({
  components: {},
})
export default class UserTransactionsListTable extends Vue {
  @Prop({required: true, default: null }) userId;
  @Prop({required: false, type: String, default: null }) title;

  loading = true;
  userTransactions = null;
  async getUserTransactions() {
    this.loading = true;
    const response = await API.getUserTransactions(this.userId);
    if (response.success) {
      this.userTransactions = response.user_transactions;
    }
    this.loading = false;
  }
  mounted(){
    this.getUserTransactions();
  }
}
