import { render, staticRenderFns } from "./UsersListTable.vue?vue&type=template&id=12809a40&scoped=true&"
import script from "./UsersListTable.vue?vue&type=script&lang=ts&"
export * from "./UsersListTable.vue?vue&type=script&lang=ts&"
import style0 from "./UsersListTable.vue?vue&type=style&index=0&id=12809a40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12809a40",
  null
  
)

export default component.exports