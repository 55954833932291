














import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class Countdown extends Vue{
  @Prop({ type: String, required: false}) saleDate;
  @Prop({ type: String, required: false}) saleTime;
  @Prop({ type: String, required: false}) timeZone;
  @Prop({ type: String, required: false}) saleDateTime;
  @Prop({ type: String, required: false, default: "type-1"}) type;

  @Watch('saleDate')
  saleDateChange(val, oldval){
    this.saleDate = val;
    this.setCountDown()
  }
  @Watch('saleTime')
  saleTimeChange(val, oldval){
    this.saleTime = val;
    this.setCountDown()
  }
  @Watch('timeZone')
  timeZoneChange(val, oldval){
    this.timeZone = val;
    this.setCountDown()
  }
  @Watch('saleDateTime')
  saleDateTimeChange(val, oldval){
    this.saleDateTime = val;
    this.setCountDown()
  }

  isCorrectFormat = true;
  isTimeLeft = true;
  showCounter = true;
  countDownDate;
  countDown = null
  dateYear = null;
  dateMonth = null;
  dateDay = null;
  dateHours = null;
  dateMinutes = null;
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  setCountDown () {
    this.countDownDate = this.dateFromString;
    if( this.countDownDate !== -1 ){
      const currentDate = new Date();
      let currentDateTime = currentDate.getTime();
      const diffInTime = this.countDownDate.getTime() - currentDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);

      if (diffInDays <= 1){
        this.countDownDate = this.countDownDate.getTime();
        if(this.showCounter === true){
          let timeLeft = this.countDownDate - currentDateTime;
          this.countDown = setInterval(() => {
            currentDateTime = new Date().getTime();
            timeLeft = this.countDownDate - currentDateTime;
            if(timeLeft <= 0){
              this.showCounter = false;
              this.isTimeLeft = false;
              clearInterval(this.countDown);
            }
            this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          }, 1000)
        }
      }else{
        this.showCounter = false;
      }
    }else{
      this.isCorrectFormat = false;
    }
  }
  get dateFromString(){
    let tmpDate;
    if(typeof this.saleDateTime !== 'undefined'){
      tmpDate = new Date(this.saleDateTime);
      this.dateYear = tmpDate.getFullYear();
      this.dateMonth = ('0' + (tmpDate.getMonth() + 1)).slice(-2);
      this.dateDay = ('0' + tmpDate.getDate()).slice(-2);
      this.dateHours = tmpDate.getHours();
      this.dateMinutes = tmpDate.getMinutes();
      return tmpDate;
    }else{
      this.dateYear = parseInt(this.saleDate.substring(0, 4));
      this.dateMonth = parseInt(this.saleDate.substring(4, 6));
      this.dateDay = parseInt(this.saleDate.substring(6));
      this.dateHours = this.saleTime.substring(0, 2);
      this.dateMinutes = this.saleTime.substring(2);
      const tmpDateString = this.dateYear + '-' + (parseInt(this.dateMonth)) + '-' +  this.dateDay + ' ' + this.dateHours + ':' + this.dateMinutes + ' ' + this.timeZone;
      tmpDate = new Date(tmpDateString);
      if(tmpDate instanceof Date && !isNaN(tmpDate.getTime())){
        return tmpDate;
      } else{
        return -1;
      }
    }
  }

  mounted(){
    this.setCountDown()
  }
}
