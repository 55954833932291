














import {Component, Vue} from 'vue-property-decorator';
import UserTransactionsListTable from "@/components/spec/UserTransactionsListTable.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import * as API from "@/store/api";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    UserTransactionsListTable,
    PageHeader,
  },
})
export default class Transactions extends Vue {
  pageName = 'Moje transakcje';
  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]
  clientData = null;
  get userId() {
    return this.$store.state.currentUser.id;
  }
  async getClient() {
    let getClientResponse = await API.getClient(this.userId);
    getClientResponse = getClientResponse.client;
    if (getClientResponse !== StatusCodes.NOT_FOUND) {
      this.clientData = getClientResponse;
    }
  }
  async mounted() {
    await this.getClient();
  }
}
