















































import { Component, Emit, Vue } from 'vue-property-decorator';
import * as Cookies from 'js-cookie';
import HeaderLoginButton from '@/components/spec/HeaderLoginButton.vue';
import * as helpers from '../../helpers';
import * as types from '../../types';
import * as API from '@/store/api';
import auth from '@/store/modules/auth';
import store from '@/store';

@Component({
  components: {
    HeaderLoginButton,
  },
})
export default class HeaderProfile extends Vue {
  isLoggedIn = false;
  emailVisible = false;
  modalAccountDetailsVisible = false;
  get user(): types.User {
    API.getCSRF();
    this.isLoggedIn = !!this.$store.state.currentUser.id;
    return this.$store.state.currentUser;
  }

  get fullname() {
    if (!this.user.fullName) {
      this.emailVisible = true;
      return this.user.email;
    }
    return this.user.fullName;
  }
  @Emit('logout')
  async logout() {
    this.isLoggedIn = false;
    return true;
  }
  @Emit('login-success')
  loginSuccess() {
    this.user;
    this.fullname;
  }
  showModalAccountDetails() {
    this.modalAccountDetailsVisible = !this.modalAccountDetailsVisible;
    return this.modalAccountDetailsVisible;
  }

  mounted() {
    const mountUser = () => this.user;
    mountUser();
  }
}
