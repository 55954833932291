










































import {Vue, Component, Emit} from "vue-property-decorator";
@Component({})
export default class LoginForm extends Vue{
  loginForm =  {
    email: '',
    password: '',
  }

  @Emit("login-form-approved")
  loginSubmit(event){
    event.preventDefault()
    return this.loginForm;
  }
  @Emit('show-registration')
  showRegistration(){
    return true;
  }
  @Emit('forgotten-password')
  forgottenPassword(){
    return true;
  }
}
