


















import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class SearchResultsSection extends Vue{
  @Prop({ type: Array, required: false, default: [] }) searchResults;
}
