























import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import AuctionTileLite from "@/components/dummy/AuctionTileLite.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import {getDateInSpecificFormat} from "@/consts";
@Component({
  components: {
    AuctionTileLite
  },
})
export default class AuctionsGrid extends Vue {
  @Prop({ type: String, required: false, default: "Nadchodzące aukcje" }) title;
  date1 = new Date('2022-04-26:15:06:50');
  dayForUpcomingAuction = consts.getDateInSpecificFormat(2);

  isVisible = false;

  data = [];

  get itemsForList() {
    return this.data;
  }

  async getAuctions() {
    console.log('getauctions');
    window.scrollTo(0,0)
    console.log(this.dayForUpcomingAuction);
    const auctionsParameter =
        {
          "page": 1,
          "pageSize": 23,
          "sortParams": {
            "field": "sale_date",
            "dir": "DESC"
          },
          "filters": {
            "year__gte": "2000",
            "high_bid__gte": "100",
            "buy_it_now_price__gte": "1",
            "sale_date__lte": this.dayForUpcomingAuction
          }
        }
    const response = await API.getAuctions(auctionsParameter);
    if(typeof response === "object"){
      console.log(response);
      this.data = response.objs;
      this.isVisible = true;
    }else{
      console.log('BLAD  ' + response);
      this.isVisible = false;
    }
  }

  mounted(){
   this.getAuctions();
  }
}
