




























import { Component, Vue, Prop, Emit} from 'vue-property-decorator';
import * as consts from '../../consts';
import AddEditOrderForm from "@/components/spec/AddEditOrderForm.vue";
import * as API from "@/store/api";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    AddEditOrderForm,
  },
})
export default class ModalAddEditOrder extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: Boolean, required: false, default: false }) isEditView;
  @Prop({ required: false, default: null }) order;

  orderResponseInfo: { success: boolean; show: boolean; message: string } = {
    show: null,
    success: null,
    message: null,
  };

  isSuccess = null;
  size = 'lg';
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  resetModal(){
    this.orderResponseInfo = {
      show: false,
      success: false,
      message: '',
    };
    this.isSuccess = null;
  }

  showModal() {
    this.show = true;
    this.$forceUpdate();
  }

  @Emit('add-edit-order-close')
  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT);
    this.resetModal();
    this.show = false;
  }

  @Emit('add-edit-order-response')
  async orderSubmit(orderData){
    this.resetModal();
    event.preventDefault()
    const params = {
      "make": orderData.makeModel,
      "vin": orderData.vin,
      "user_id": orderData.user,
      "offer_number": orderData.offerNumber,
      "auction_type": orderData.auctionType,
      "amount": orderData.orderAmount,
      "currency": orderData.orderAmountCurrency,
      "status": orderData.status,
      "custom_agency": orderData.customsAgency,
    }
    let response;
    if(this.isEditView){
      response = await API.updateOrder(params);
    }else{
      response = await API.saveOrder(params);
    }
    switch(response.status){
      case StatusCodes.OK:{
        if(response.success){
          this.isSuccess = true;
          return true;
        }else{
          this.orderResponseInfo.show = true;
          this.isSuccess = false;
          this.orderResponseInfo.success = false;
          if(response.error_code){
            switch(response.error_code){
              case '2':
                this.orderResponseInfo.message = 'Błąd! Sprawdź formularz.';
                break;
              case '3':
                this.orderResponseInfo.message = 'Błąd! Brak użytkownika w bazie danych.';
                break;
              case '5':
                this.orderResponseInfo.message = 'Błąd! Zamówienie zostało już przypisane do użytkownika';
                break;
              default:
                this.orderResponseInfo.message = 'Błąd! Proszę spróbować ponownie ponownie później.';
            }
          }
        }
        break;
      }
      default: {
        this.orderResponseInfo.show = true;
        this.orderResponseInfo.success = false;
        this.orderResponseInfo.message = 'Błąd! Proszę spróbować ponownie ponownie później.';
      }
    }
    return false;
  }
}
