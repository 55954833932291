















import BannerWithCarSearch from "@/components/spec/BannerWithCarSearch.vue";
import { Component, Vue, Ref } from 'vue-property-decorator';
import ModalInfo from "@/components/dummy/ModalInfo.vue";

@Component({
  components: {
    ModalInfo,
    BannerWithCarSearch
  },
})
export default class NoAuthorization extends Vue {
  @Ref() readonly InfoModal: ModalInfo
  infoModalTitle = '';
  infoModalText = '';

  hideModalInfo(){
    this.$router.push('/');
  }

  mounted() {
    this.infoModalTitle = 'Wystąpił błąd!';
    this.infoModalText = 'Brak uprawnień do wyświetlenia strony. Po zamknięciu tego okna zostaniesz przekierowany do strony głównej.';
    this.InfoModal.showModal();
  }
}
