













import * as API from '../../store/api';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Dashboard extends Vue {
  show = true;
  users = [];
  isNew = false;

  showModal() {
    // this.dashboardmodal.showModal(true);
  }
  logout() {
    API.logoutUser();
    this.$router.push('/');
  }
  getUsers() {
    API.getAllUsers()
      .then(response => {
        console.log(response.data);
        this.users = response.data.users;
      })
      .catch(err => {
        console.log(err);
      });
  }

  mounted() {
    console.log('Akcje w trakcie tworzenia komponentu..');
  }
}
