




















import { Component, Vue } from 'vue-property-decorator';
import * as helpers from '../../helpers';
import * as types from '../../types';

@Component({
  components: {},
})
export default class ProfileMenu extends Vue {
  get user(): types.User {
    console.log('GetUSER', helpers.currentUser);
    return this.$store.state.currentUser;
  }

  get fullname() {
    return this.user.fullName;
  }

  private logoutUser(): any {
    //userModule.logout();
    this.$router.push({ name: 'Login' });
  }
}
