
















































import {Component, Vue, Prop, Emit} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class SingleOrderTable extends Vue {
  @Prop({ required: true }) order;
  @Prop({ type: Array, required: false, default: "" }) statusesList;
  @Prop({ type: Boolean, required: false, default: false }) isAdmin;

  @Emit('edit-order')
  editOrder(){
    return true;
  }
}
