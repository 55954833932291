










import { Vue, Component } from 'vue-property-decorator';
import TopPanel from '../components/dummy/TopPanel.vue';
import Header from '../components/dummy/Header.vue';
import Footer from '../components/dummy/Footer.vue';
import auth from '../store/modules/auth';
import * as API from '../store/api';

@Component({
  components: { Footer, Header, TopPanel },
})
export default class MainView extends Vue {
  onResize() {
    //console.log("InnerWidth:", window.innerWidth);
    //app.isMobile = window.innerWidth < 600;
  }

  async mounted() {
    console.log('MainView mounted');
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  get getInner() {
    return window.innerWidth;
  }

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }
}
