



































import {Vue, Component, Prop} from "vue-property-decorator";
import FormSelect from "@/components/dummy/FormSelect.vue";
import {capacityCurrencies} from "@/consts";
import { validationMixin } from 'vuelidate';
import {between, required} from "vuelidate/lib/validators";
import * as API from "@/store/api";
@Component({
  components:{
    FormSelect
  },
  mixins: [validationMixin],
  validations: {
    capacityForm: {
      value: {
        required,
        between: between(0, 999999999)
      },
      currency: {

      },
      currencyIndex: {

      }
    }
  },
})
export default class EditFinancialCapacity extends Vue{
  @Prop({ required: false, default: null }) userData;
  capacityCurrencies = capacityCurrencies;
  capacityForm = {
    value: null,
    currency: null,
    currencyIndex: 0,
  }
  orderResponseInfo = {
    show: null,
    success: null,
    message: null,
  };
  onPaste (evt) {
    evt.preventDefault();
  }

  isNumber (evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault()
    }
  }
  async setMarginCall(){
    const params = {
      "user_id": this.userData.user_id,
      "margin_call": this.capacityForm.value,
      "margin_call_currency": this.capacityForm.currency
    }
    const response = await API.setMarginCall(params);
    this.orderResponseInfo.show = true;
    if(response.success){
      this.orderResponseInfo.success = true;
      this.orderResponseInfo.message = 'Gotowe!';
    }else{
      this.orderResponseInfo.success = false;
      this.orderResponseInfo.message = 'Błąd. Spróbuj ponownie.';
    }
  }
  currencyChange(value){
    this.capacityForm.currency = value;
    this.setCurrencyIndex();
  }
  setCurrencyIndex(){
    const currency = this.capacityForm.currency;
    this.capacityForm.currencyIndex = this.capacityCurrencies.findIndex(function(item, i){
      return item.value === currency;
    });
    this.capacityForm.currencyIndex = this.capacityCurrencies.findIndex(
        function(item, i){
          return item.value === currency;
        }) !== -1? this.capacityForm.currencyIndex: 0;
  }
  getCapacityFromUserData(){
    if(this.userData){
      this.capacityForm.value = (Object.prototype.hasOwnProperty.call(this.userData, 'margin_call'))? this.userData.margin_call: 0;
      this.capacityForm.currency = (Object.prototype.hasOwnProperty.call(this.userData, 'margin_call_currency'))? this.userData.margin_call_currency: 0;
      this.setCurrencyIndex();
    }
  }
  submitForm(){
    this.orderResponseInfo.show = false;
    this.$v.$touch()
    if (!this.$v.$invalid) {
      this.setMarginCall();
    }else{
      this.orderResponseInfo.show = true;
      this.orderResponseInfo.success = false;
      this.orderResponseInfo.message = 'Błąd. Sprawdź poprawność danych.';
    }
  }
  mounted(){
    this.getCapacityFromUserData();
  }
}
