





















import { Component, Vue, Prop, Ref, Emit } from "vue-property-decorator";
import {ModalEvents, StatusCodes} from "../../consts";
import AccountDetailsForm from '@/components/spec/AccountDetailsForm.vue';
import * as API from "@/store/api";

@Component({
  components:{
    AccountDetailsForm,
  }
})
export default class ModalAccountDetails extends Vue {
  @Prop({ type: String, required: false, default: "lg" }) size;

  private show = false;
  private events: [ModalEvents.OK_EVENT, ModalEvents.CANCEL_EVENT];
  private clientData = null;
  private hasClientData = null;

  @Emit()
  modalClose() {
    return 1;
  }
  showModal() {
    this.getClient();
    this.show = true;
  }
  saveModal() {
    this.$emit(ModalEvents.OK_EVENT);
    this.show = false;
  }
  @Emit("hide-modal-account-details")
  hideModal() {
    this.show = false;
    this.hasClientData = null;
  }
  get userId() {
    return this.$store.state.currentUser.id;
  }
  async getClient(){
    if(this.userId){
      let getClientResponse = await API.getClient(this.userId);
      getClientResponse = getClientResponse.client;
      if(getClientResponse !== StatusCodes.NOT_FOUND){
        this.clientData = getClientResponse;
        if(Object.prototype.hasOwnProperty.call(getClientResponse, 'contact')){
          getClientResponse = getClientResponse.contact;
          if(Object.prototype.hasOwnProperty.call(getClientResponse, 'name')){
            getClientResponse = getClientResponse.name;
            if(getClientResponse.length){
              this.hasClientData = true;
            }else{
              this.hasClientData = false;
            }
          }
        }
      }
    }
  }
  mounted(){
    this.getClient();
  }
}
