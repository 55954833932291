import Vue from 'vue';
import App from './App.vue';
import VueResource from "vue-resource";
import router from './router';
import store from './store';
import './registerServiceWorker';
import * as deps from './dependencies';

Vue.use(VueResource);
(Vue as any).http.options.root = 'http://localhost/XAMPP/153.Corpline-wp/wp-json/';

Vue.config.devtools = true;

new Vue({
  router,
  store,
  beforeCreate() {
    deps
      .loadFromDB()
      .then(() => {
        //deps.loadStore();
        //deps.saveToDB();
      })
      .catch(err => {
        console.error(err);
      });
  },
  render: h => h(App),
  created() {
    const watchers = this.$store._vm._computedWatchers;
    Object.keys(watchers).forEach(key => {
      watchers[key].watcherName = key;
    });
  },
}).$mount('#app');
