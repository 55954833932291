


























import * as API from '../../store/api';
import auth from '../../store/modules/auth';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import PasswordResetFormPart2 from "@/components/spec/PasswordResetFormPart2.vue";
import {StatusCodes} from "@/consts";
@Component({
  components: {
    PasswordResetFormPart2,
  },
})
export default class ModalPasswordResetPart2 extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: "default-text" }) messageClass;
  size = 'sm';
  showForm = true;
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};

  passwordReset2ResponseInfo = {
    show: false,
    status: 0,
    message: '',
  };

  passwordResetPart2Form = {
    password: '',
    repeatedPassword: '',
  };

  showModal(params = {}) {
    this.show = true;
  }

  hideModal() {
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  @Emit("reset-password-part-2-approved")
  passwordResetPart2Success(){
    return this.passwordResetPart2Form;
  }

  @Emit("cancel")
  cancel(){
    return false;
  }

  reset(){
    console.log('reset-2');
  }
}
