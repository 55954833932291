import { render, staticRenderFns } from "./ModalAddEditTransaction.vue?vue&type=template&id=4ba1026c&scoped=true&"
import script from "./ModalAddEditTransaction.vue?vue&type=script&lang=ts&"
export * from "./ModalAddEditTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./ModalAddEditTransaction.vue?vue&type=style&index=0&id=4ba1026c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba1026c",
  null
  
)

export default component.exports