


























import {Component, Ref, Vue} from 'vue-property-decorator';
import PageHeader from "@/components/dummy/PageHeader.vue";
import PurchaseCostCalculator from "@/components/dummy/PurchaseCostCalculator.vue";
import CRMOrderListTable from "@/components/spec/CRMOrderListTable.vue";
import ModalAddEditOrder from "@/components/spec/ModalAddEditOrder.vue";
import * as API from "@/store/api";
import * as consts from '../../consts';

@Component({
  components: {
    ModalAddEditOrder,
    PageHeader,
    PurchaseCostCalculator,
    CRMOrderListTable
  },
})
export default class OrdersList extends Vue {
  @Ref() readonly AddOrderModal: ModalAddEditOrder;

  pageName = 'Lista zleceń';
  ordersList = null;
  loading = true;
  breadcrumbItems =  [
    {
      text: this.pageName,
      active: true
    }
  ];

  statusesList = consts.statusesList;
  async getOrderList() {
    this.loading = true;
    const response = await API.getOrders();
    if(response.success){
      this.ordersList = response.orders;
    }
    this.loading = false;
  }
  showAddOrderModal(){
    this.AddOrderModal.showModal();
    return true;
  }
  addEditOrderResponse(response){
    // force refresh list if new item was inserted from modal
    if(response){
      this.getOrderList();
      return true;
    }
    return false;
  }
  created(){
    this.getOrderList();
  }
}
