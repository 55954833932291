<template>
  <div class="search-input">
    <router-link :to="{name: 'searchResults'}"  class="button type-1 search-button"><img src="@/assets/search-icon.svg" alt="search loupe"></router-link>

<!--    <div class="search-input">-->
<!--      <input-->
<!--          type="text"-->
<!--          id="search"-->
<!--          name="search"-->
<!--          placeholder="Czego szukasz?"-->
<!--          :class="{ dark }"-->
<!--          :value="value"-->
<!--          @input="handleChange"-->
<!--      />-->
<!--      <div class="button type-3"><span>Szukaj</span></div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'HeaderSearchElement',
  props: {
    value: {
      type: String,
      required: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.search-input {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  .search-button{
    height: 100%;
    border-color: $color18;
    padding: 1rem;
    @include breakpoint($desktop){
      &:hover{
        background: $color9;
      }
    }
  }
  input {
    border-radius: 0.8rem 0 0 0.8rem;
    padding: 1.1rem 3rem 1.1rem 5.5rem;
    background-image: url('~@/assets/search-icon.svg');
    background-position: 2.1rem 1.2rem;
    background-repeat: no-repeat;
    width: 27.6rem;
    @include breakpoint($container-w) {
      width: custom_size(276);
      padding-right: custom_size(55);
    }
    @include breakpoint($container-w1) {
      font-size: $px-14-0;
      background-position: 2.1rem 0.8rem;
    }
    @include breakpoint($container-w2) {
      padding-left: 40px;
      padding-right: 14px;
      width: 160px;
      background-position: 12px 0.8rem;
    }

    &:focus {
      outline: none;
      border-color: #000000;
    }
  }
  .button {
    @include breakpoint($container-w1) {
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: $px-14-0;
    }
  }
}
.dark {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000000;
}
</style>
