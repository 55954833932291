import store from '@/store';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'auth',
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  isAuthenticated = false;
  isSuperUser = false;
  user = null;
  csrf = '';

  @Mutation
  setAuthenticated(isAuthenticated) {
    this.isAuthenticated = isAuthenticated;
  }
  @Mutation
  setSuperUser() {
    this.isSuperUser = false;
    if(store.state.currentUser){
      this.isSuperUser = store.state.currentUser['is_superuser'];
    }
  }
  @Mutation
  setCsrf(csrf) {
    this.csrf = csrf;
  }
  @Mutation
  login(user) {
    this.isAuthenticated = true;
    this.user = user;
  }
  @Mutation
  clear() {
    this.isAuthenticated = false;
    this.user = null;
  }
}

export default getModule(AuthModule);
