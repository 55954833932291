

















import {Component, Vue} from 'vue-property-decorator';
import PageHeader from "@/components/dummy/PageHeader.vue";
import PurchaseCostCalculator from "@/components/dummy/PurchaseCostCalculator.vue";
import UserOrdersListTable from "@/components/spec/UserOrdersListTable.vue";
import CRMUserOrdersListTable from "@/components/spec/CRMUserOrdersListTable.vue";

@Component({
  components: {
    CRMUserOrdersListTable,
    UserOrdersListTable,
    PageHeader,
    PurchaseCostCalculator,
  },
})
export default class Orders extends Vue {
  pageName = 'Moje zlecenia';
  ordersList = null;
  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]

  get userId() {
    return this.$store.state.currentUser.id;
  }
}
