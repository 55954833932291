








































































import {Vue, Component, Prop, Emit} from "vue-property-decorator";

@Component({
  components:{}
})
export default class AccountDetailsPart1Form extends Vue{
  @Prop({ required: true }) accountType;
  mutableAccountType = null;
  @Emit("account-details-part-1-selected")
  accountDetailsPart1Select(event){
    event.preventDefault()
    return this.mutableAccountType;
  }
  selectAccountType(clickedAccountType){
    this.mutableAccountType = clickedAccountType;
  }
  mounted(){
    this.mutableAccountType = this.accountType;
  }
}
