import Vue from 'vue';
import izi from 'izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import * as consts from '@/consts';
import * as API from '@/store/api';
import moment, { max } from 'moment';
import * as types from '../types';
import * as iziTypes from 'izitoast/types';
import store from '@/store/index';

export const onlineHelper = Vue.observable({
  isOnline: navigator.onLine,
});

console.log('Add event listener online/offline');
window.addEventListener('online', () => {
  onlineHelper.isOnline = true;
  console.log('ONLINE listener');
});
window.addEventListener('offline', () => {
  onlineHelper.isOnline = false;
  console.log('OFFLINE listener');
});

export function checkNIP(nip) {
  const nipWithoutDashes = nip.replace(/-/g, '');
  const reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) == false) {
    return false;
  } else {
    const digits = ('' + nipWithoutDashes).split('');
    const checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;

    return parseInt(digits[9]) == checksum;
  }
}

export function checkMail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function checkPhoneNumber(number: string) {
  const re = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
  return re.test(String(number).toLowerCase());
}

export function checkPostalCode(postalCode: string) {
  const re = /^dd-ddd$/;
  return re.test(String(postalCode).toLowerCase());
}

export function statusArrAsDefaultMultiselect(arr: any[]) {
  return arr.map(el => {
    return { id: el.id, name: el.name };
  });
}

export function translateDate(dateString: string) {
  console.log(dateString, 'translating date');
  const translations = {
    January: 'Styczeń',
    February: 'Luty',
    March: 'Marzec',
    April: 'Kwiecień',
    May: 'Maj',
    June: 'Czerwiec',
    July: 'Lipiec',
    August: 'Sierpień',
    September: 'Wrzesień',
    October: 'Październik',
    November: 'Listopad',
    December: 'Grudzień',
    Monday: 'poniedziałek',
    Tuesday: 'wtorek',
    Wednesday: 'środa',
    Thursday: 'czwartek',
    Friday: 'piątek',
    Saturday: 'sobota',
    Sunday: 'niedziela',
  };
  const splitted = dateString.split(' ');

  for (let i = 0; i < splitted.length; i++) {
    if (translations[splitted[i]]) {
      splitted[i] = translations[splitted[i]];
    }
  }
  return splitted.join(' ');
}

export function requestSucess(message = 'Pomyślnie wykonano!', timeout = 2000, position = 'center') {
  izi.show({
    message: message,
    title: 'Komunikat',
    position: position as iziTypes.IziToastPosition,
    timeout: timeout,
    color: 'green',
  });
}

export function info(message: string, timeout = 2000, position = 'center') {
  izi.show({
    message: message,
    title: 'Komunikat',
    position: position as iziTypes.IziToastPosition,
    timeout: timeout,
    color: 'green',
  });
}

export function messageInfo(sender: string, content: string) {
  izi.show({
    message: content,
    title: sender,
    position: 'bottomRight',
    timeout: false,
    color: 'green',
  });
}

export function warn(message: string, timeout = 2000) {
  izi.show({
    message: message,
    title: 'Uwaga',
    position: 'center',
    timeout: timeout,
    color: 'yellow',
  });
}

export function error(message: string) {
  izi.show({
    message: message,
    title: 'Błąd',
    position: 'center',
    timeout: 2000,
    color: 'red',
  });
}

export function isValidNip(nip: string) {
  //    if (typeof nip !== "string")
  //        return false;

  nip = nip.replace(/[\\-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
}

export function isValidPostalCode(postalCode: string) {
  const regex = /\d{2}-\d{3}/g;
  return postalCode.match(regex);
  //return new RegExp(regex).test(postalCode);
}

export function setTempId(array: object[], obj: object) {
  console.log('setTempId', array, obj);
  const maxId = Math.max(...array.map(o => o['id']));
  if (obj['id'] === 0 || obj['id'] === -1) {
    if (maxId < consts.NEW_ID_START) {
      obj['id'] = consts.NEW_ID_START;
    } else {
      obj['id'] = maxId + 1;
    }
  }
}

export function getServerId(array: object[], obj: object) {
  console.log('getServerID', obj);
  if (array.length > 0) {
    const maxId = Math.max(...array.filter(el => el['id'] < consts.NEW_ID_START).map(o => o['id']));
    console.log('NewID=', maxId + 1);
    return maxId + 1;
  } else {
    return 1;
  }
}

export function createNumber(id: number) {
  return id.toString() + '/' + moment().year().toString();
}

export function truncate(value: any, max: number) {
  if (value.toString().length > max) {
    return parseInt(value.toString().slice(0, max));
  }
  return value;
}

export function uniqueArray(arr: any) {
  return [...new Set(arr)];
}

export function isUserAuthorisedOfSymbol(symbol: string) {
  const user = store.state.currentUser;
  if (!user || !user.rights) {
    console.error('user not loaded');
    return false;
  }
  const index = user.rights.findIndex(el => {
    return el.symbol === symbol;
  });
  return index !== -1;
}

export function currentUser(): types.User {
  return this.$store.state.currentUser as types.User;
}

export function getDictValue(dicts, type, value) {
  //console.log('getDict', dicts, type, value);
  return dicts.find(d => d.value === value && d.type === type);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hoursRange = [
  { hour: '07:00', from: '6:00', to: '14:00' },
  { hour: '15:00', from: '14:00', to: '22:00' },
  { hour: '23:00', from: '22:00', to: '6:00' },
];

export function getHourBasedOnPeriods(now = moment(), hours = hoursRange) {
  let output = '';
  hours.forEach(period => {
    const periodStartTime = moment(period.from, 'HH:mm');
    const periodEndTime = moment(period.to, 'HH:mm');
    if (!output) {
      if (now.isSameOrAfter(periodStartTime, 'minute')) {
        if (now.isBefore(periodEndTime, 'minute')) {
          output = period.hour;
        } else {
          if (periodEndTime.isBefore(periodStartTime)) {
            output = hours[hours.length - 1].hour;
          }
        }
      } else {
        output = hours[hours.length - 1].hour;
      }
    }
  });
  console.log('NOW:', now.format('HH:mm'), ' output', output);
  return output;
}
