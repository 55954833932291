





















































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import FormSelect from "@/components/dummy/FormSelect.vue";
import Odometer from "@/components/dummy/Odometer.vue";
@Component({
  components: {
    FormSelect,
    Odometer,
  },
})
export default class AuctionDetails extends Vue {
  @Prop() auctionData;
  @Prop({ type: String, required: false, default: 'copart' }) type;
  @Prop({ type: String, required: false }) vin;
  selected = null;
  shipperOptions = [
    { value: 'cargoloop', text: 'Cargoloop' },
  ];
  customsAgencyOptions = [
    { value: 'default', text: 'Domyślna' },
  ];
}
