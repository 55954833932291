













































import {Component, Vue, Prop} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class AdditionalSingleOrderInfoTable extends Vue {
  @Prop({ required: true }) order;
}
