






































import {Component, Vue, Prop, Emit, Watch} from 'vue-property-decorator';
import * as consts from '../../consts';
import * as API from "@/store/api";
@Component({
  components: {
  },
})
export default class ModalBuyNowConfirmation extends Vue {
  @Prop({type: String, required: true}) model;
  @Prop({type: String, required: true}) engine;
  @Prop({type: String, required: true}) VIN;
  @Prop({type: String, required: true}) lotNumber;
  @Prop({type: Number, required: true}) auctionType;
  @Prop({required: true}) price;
  @Prop({type: String, required: true}) currency;
  @Prop({type: String, required: true}) thumbnail;
  @Prop({type: Boolean, required: false, default: false}) offerExistsInUserBids;
  size = 'sm';
  show = false;
  status = null;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  title = null;
  loading = null;
  description = null;
  thumbnailModified;
  priceModified = null;
  mutableOfferExistsInUserBids = this.offerExistsInUserBids;
  showModal(params = {}) {
    this.resetModal();
    this.show = true;
  }
  resetModal(){
    this.status = null;
    this.loading = null;
    this.description = null;
    this.title = 'Potwierdzasz zlecenie zakupu?';
  }
  hideModal() {
    this.show = false;
  }
  @Watch('offerExistsInUserBids', {immediate: true})
  offerChange(val, oldVal) {
    // this.offerExistsInUserBids = val;
    this.mutableOfferExistsInUserBids = val;
  }
  @Emit('buy-now')
  async buyNow() {
    this.loading = true;
    const auctionTypeIndex = this.auctionType;
    const auctionTypeText = consts.auctionTypes.filter(function (el){
      return el.value === auctionTypeIndex;
    })
    const params = {
      "user_id": this.$store.state.currentUser.id,
      "user_first_name": this.$store.state.currentUser.first_name,
      "user_last_name": this.$store.state.currentUser.last_name,
      "email": this.$store.state.currentUser.email,
      "imgSrc": this.thumbnailModified,
      "make": this.model,
      "amount": this.priceModified,
      "vin": this.VIN,
      "id": this.lotNumber,
      "auction_type": auctionTypeIndex,
      "auction_type_text": auctionTypeText[0].text,
      "amount_currency": this.currency
    };
    let buyNowResponse;
    if(this.mutableOfferExistsInUserBids){
      buyNowResponse = await API.bidBuyNowVehicle("buy_now", params, "put");
    }else{
      buyNowResponse = await API.bidBuyNowVehicle("buy_now", params, "post");
    }
    this.loading = false;
    switch (buyNowResponse) {
      case consts.StatusCodes.OK:
        this.status = 'success';
        this.title = 'Gotowe!';
        this.description = 'Zlecenie zostało przyjęte i jest weryfikowane przez zespół Easyimport.';
        this.mutableOfferExistsInUserBids = true;
        break;
      default:
        this.status = 'error';
        this.title = 'Błąd!';
        this.description = 'Spróbuj ponownie lub skontaktuj się z administratorem.';
    }
    return this.mutableOfferExistsInUserBids;
  }
  mounted(){
    this.resetModal();
    if(this.auctionType === 0){
      this.thumbnailModified = "https://" + this.thumbnail;
      this.priceModified = this.price;
    }else{
      this.thumbnailModified = this.thumbnail;
      this.priceModified = this.price;
      // this.priceModified = parseFloat(this.price.substring(1));
    }
  }
}
