












































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import {required, minLength, sameAs, email} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
@Component({
  mixins: [validationMixin],
  validations: {
    passwordResetPart2Form: {
      password: {
        required,
        minLength: minLength(6)
      },
      repeatedPassword: {
        sameAsPassword: sameAs('password')
      },
    },
  }
})
export default class PasswordResetFormPart2  extends mixins(Vue, validationMixin){
  passwordResetPart2Form = {
    password: '',
    repeatedPassword: '',
  }
  submitStatus = ''
  registerCheckboxStatus = null;
  checkboxCheck(event){
    if (event) {
      this.registerCheckboxStatus = true;
      return true;
    }else{
      this.registerCheckboxStatus = false;
      return false;
    }
  }

  @Emit("password-reset-form-part-2-approved")
  passwordResetFormPart2Submit(){
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false;
    } else {
      return this.passwordResetPart2Form;
    }
  }

  @Emit('cancel')
  cancel(){
    return true;
  }

}
