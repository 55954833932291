

























import {Vue, Component, Emit, Prop} from "vue-property-decorator";
import AccountDetailsPart1Form from "@/components/spec/AccountDetailsPart1Form.vue";
import AccountDetailsPart2Form from "@/components/spec/AccountDetailsPart2Form.vue";
import AccountDetailsPart3Form from "@/components/spec/AccountDetailsPart3Form.vue";

@Component({
  components:{
    AccountDetailsPart1Form,
    AccountDetailsPart2Form,
    AccountDetailsPart3Form
  }
})
export default class AccountDetailsForm extends Vue{
  @Prop({ required: false }) clientData;
  accountType = null;
  selectedAccountType: number;
  personalData: '';

  showAccountFormPart1 = true;
  showAccountFormPart2 = false;
  showAccountFormPart3 = false;

  @Emit("account-details-modal-hide")
  accountDetailsModalHide(){
    return true;
  }
  accountDetailsPart1Selected(selectedAccountType){
    this.accountType = selectedAccountType;
    this.showAccountFormPart1 = false;
    this.showAccountFormPart2 = true;
  }

  accountDetailsPart2Submitted(personalData){
    this.personalData = personalData;
    if(this.personalData){
      switch(this.accountType){
        case 0:
          this.showAccountFormPart2 = false;
          this.showAccountFormPart3 = true;
          break;
        case 1:
          this.accountDetailsModalHide();
          break;
      }
      return 0;
    }
  }

  accountDetailsPart2Back(){
    this.showAccountFormPart2 = false;
    this.showAccountFormPart1 = true;
  }

  accountDetailsPart3Back(){
    this.showAccountFormPart3 = false;
    this.showAccountFormPart2 = true;
  }

  accountDetailsPart3Submitted(){
    this.accountDetailsModalHide();
  }
  getClientType(){
    this.accountType = this.clientData['type']? 1: 0;
  }
  mounted(){
    this.getClientType();
  }
}
