














































import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
  components: {}
})
export default class PaginationLite extends Vue{
  @Prop({ type: Number, required: true, default: 3 }) totalElements;
  @Prop({ type: Number, required: true, default: 12 }) perPage;
  @Prop({ type: Number, required: true, default: 1 }) currentPage;

  get totalPages(){
    return this.totalElements / this.perPage >=1? Math.floor(this.totalElements / this.perPage ): 1;
  }
  get isInFirstPage() {
    return this.currentPage === 1;
  }
  get isInLastPage() {
    return this.currentPage === this.totalPages;
  }
  onClickPreviousPage() {
    this.$emit('pagechanged', this.currentPage - 1);
  }
  onClickNextPage() {
    this.$emit('pagechanged', this.currentPage + 1);
  }
  onClickLastPage() {
    this.$emit('pagechanged', this.totalPages);
  }
}
