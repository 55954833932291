


















































import {Component, Vue} from 'vue-property-decorator';
import AuctionBidDetails from "@/components/dummy/AuctionBidDetails.vue";
import AuctionDetails from "@/components/spec/AuctionDetails.vue";
import PurchasedTile from "@/components/dummy/PurchasedTile.vue";
import AuctionReportsOrder from "@/components/spec/AuctionReportsOrder.vue";
import Accordion from "@/components/spec/Accordion.vue";
import FormSelect from "@/components/dummy/FormSelect.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
@Component({
  components: {
    AuctionBidDetails,
    AuctionDetails,
    PurchasedTile,
    AuctionReportsOrder,
    Accordion,
    FormSelect,
    PageHeader,
  },
})
export default class PurchasedVehicles extends Vue {
  pageName = 'Zakupione samochody';
  userOrders = null;

  purchasedVehicles = []

  loading = true;
  data = [];

  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]
  private faqList = [];
  get itemsForList() {
    return this.data;
  }
  private filterOrdersToPurchased(){
    for (let i = 0; i < this.userOrders.length; i++){
      if(consts.purchasedOrderStatuses.includes(this.userOrders[i].status)){
        this.purchasedVehicles.push(this.userOrders[i]);
      }
    }
    this.getPurchasedCargoloop();
  }
  private async getUserOrders() {
    this.loading = true;
    const response = await API.getUserOrders(this.userId);
    if (response.success) {
      this.userOrders = response.user_orders;
    }
    this.filterOrdersToPurchased();
  }
  async getPurchasedCargoloop(){
    window.scrollTo(0,0)
    this.loading = true;
    for (const purchasedVehicleVIN of this.purchasedVehicles){
      const response = await API.getPurchasedCargoloop(purchasedVehicleVIN.VIN);
      if(typeof response === "object"){
        this.data.push(response);
      }
    }
    this.loading = false;
  }
  get userId() {
    return this.$store.state.currentUser.id;
  }
  mounted(){
    this.getUserOrders();
    this.faqList = consts.defaultHelpList;
  }
}
