





































import {Component, Ref, Vue} from 'vue-property-decorator';
import AdditionalSingleOrderInfoTable from "@/components/spec/AdditionalSingleOrderInfoTable.vue";
import ModalAddEditOrder from "@/components/spec/ModalAddEditOrder.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import SingleOrderTable from "@/components/spec/SingleOrderTable.vue";
import * as consts from "@/consts";
import {StatusCodes, statusesList} from "@/consts";
import * as API from "@/store/api";

@Component({
  components: {
    AdditionalSingleOrderInfoTable,
    SingleOrderTable,
    PageHeader,
    ModalAddEditOrder,
  },
})
export default class SingleOrder extends Vue {
  @Ref() readonly AddOrderModal: ModalAddEditOrder;

  pageName = '';
  orderId = '';
  orderData = null;
  statusesList = statusesList;
  breadcrumbItems = null;
  loading = true;

  async getSingleOrder() {
    this.loading = true;
    const response = await API.getOrder(this.orderId);
    if(response){
      this.orderData = response;
    }
    this.loading = false;
  }

  mounted(){
    if(typeof this.$route.params !== 'undefined'){
      this.orderId = this.$route.params.id ? this.$route.params.id: '';
      this.setPageName();
    }
    this.getSingleOrder();
  }
  setPageName(){
    this.pageName = 'Zlecenie ' + this.orderId;
    this.breadcrumbItems =  [
      {
        text: this.pageName,
        active: true
      }
    ];
  }
  showAddOrderModal(){
    this.AddOrderModal.showModal();
  }
  addEditOrderClose(){
    this.getSingleOrder();
  }
}
