



































import {Vue, Component, Prop} from "vue-property-decorator";

@Component({
  components:{
  }
})
export default class FinancialCapacityCalculator extends Vue{
  @Prop({ type: String, required: false, default: "type-1" }) type;

  calcInput = 0;
  calcOutput = 0;

  onPaste (evt) {
    evt.preventDefault();
  }

  isNumber (evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
    const keyPressed: string = evt.key;
    console.log(keyPressed);
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault()
    }
  }

  get calculatedCarValue(){
    this.calcOutput = this.calcInput / 4;
    return this.calcOutput;
  }

}
