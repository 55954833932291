






























import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import * as consts from "@/consts";
import * as API from "@/store/api";
import Countdown from "@/components/dummy/Countdown.vue";
@Component({
  components: {
    Countdown
  },
})
export default class AuctionTileLite extends Vue {
  @Prop() auctionData;
  @Prop({ type: Boolean, required: false, default: false }) withCountDown;

  @Watch('auctionData')
  auctionDataChange(val, oldVal) {
    console.log('change');
    this.setThumbnail();
  }
  imgDefault = consts.auctionImageDefaultSource;
  thumbnail = this.imgDefault;
  goToSingleAuction(){
    window.scrollTo(0,0);
    this.$router.push({
      name: 'singleAuction',
      params: {
        obj: this.auctionData
      },
      query: { id: this.auctionData.lot_number }
    });
  }
  async setThumbnail(){
    const last  = '/' + this.auctionData.image_url.split("/").pop();
    const galleryJson = { "param" : last };

    const response = await API.getAuctionImages(galleryJson);
    if(typeof response === "object"){
      if(typeof response.lotImages !== 'undefined'){
        const linkObject = JSON.parse(JSON.stringify(response.lotImages[0]["link"]));
        for (const linkEl of Object.keys(linkObject)) {
          let isHdImage = null;
          let isThumbNail = null;
          let tmpUrl = null;
          isHdImage = linkObject[linkEl]["isHdImage"];
          isThumbNail = linkObject[linkEl]["isThumbNail"];
          tmpUrl = linkObject[linkEl]["url"];
          if(!isThumbNail && isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(!isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
        }
      }else{
        this.thumbnail = 'http://' + this.auctionData.image_thumbnail;
      }
    }else{
      console.log('BLAD  ' + response);
      this.thumbnail = 'http://' + this.auctionData.image_thumbnail;
    }
  }
  mounted(){
    this.setThumbnail();
  }
}
