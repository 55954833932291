













import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import * as consts from "@/consts";

@Component({
  components: {

  }
})
export default class FormSelect extends Vue{
  @Prop({ type: String, required: false, default: "default" }) type;
  @Prop({ type: String, required: false, default: "" }) label;
  @Prop({ required: false }) options;
  @Prop({ required: false, default: null}) selectedIndex;
  @Prop({ type: Boolean, required: false, default: false}) selectingValue;
  @Prop({ type: Boolean, required: false, default: false}) disabled;
  @Prop({ type: Boolean, required: false, default: true}) initialEmit;
  @Prop({ type: Boolean, required: false, default: true}) isDefaultStructure;
  mutableOptions = null;
  selected = null;

  @Watch('options', { immediate: true })
  optionsChange(val, oldVal) {
    this.options = val;
    this.mutableOptions = this.getMutableOptions();
  }
  getSelectedItem(val){
    if(this.mutableOptions && this.selectedIndex !== null){
      if(!this.selectingValue) {
        this.selectedIndex = val;
        return this.mutableOptions[this.selectedIndex].value;
      }else{
        const tmpObject = this.mutableOptions.filter(function (el){
          return el.value === val;
        })
        if(tmpObject.length && tmpObject[0] !== 'undefined'){
          return tmpObject[0].value;
        }else{
          return 0;
        }
      }
    }
  }
  @Watch('selectedIndex')
  selectedIndexChange(val, oldVal) {
    this.selected = this.getSelectedItem(val);
    this.$forceUpdate();
  }
  @Emit('select-change')
  selectChange(){
    return this.selected;
  }
  getMutableOptions(){
    if(this.isDefaultStructure){
      return this.options;
    }
    const tmpMutableOptions = [];
    this.mutableOptions = [];
    this.options.forEach(function(value){
      const jsonEl = { "value": value.vehicle_type, "text": value.type_pl }
      tmpMutableOptions.push(jsonEl);
    });
    return tmpMutableOptions;
  }
  async mounted() {
    this.mutableOptions = this.getMutableOptions();
    this.selected = this.getSelectedItem(this.selectedIndex);
    if (this.initialEmit) {
      this.selectChange();
    }
  }
}
