


























import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class SearchSection extends Vue{
  searchForm =  {
    search: '',
  }
  @Prop({ type: String, required: false, default: "" }) text;

  @Emit('search-submit')
  searchSubmit(event){
    event.preventDefault()
    return this.searchForm;
  }
}
