import { Enumify } from 'enumify';

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT2 = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const NEW_ID_START = 100000;
export const TEST_ID_FIRST = 200000;
export const TEST_ID_SECOND = 200001;
export const TEST_ID_THIRD = 200002;

export const TEMP_SERVER_ID = 500000;
export const NEW_PARAM_ID_START = 100000000;

export const SECTION_DICT_TYPE = 3;

export const ODOMETER_MULTIPLIER = 1.60934;
export const IAAI_HOST_VALUE = 'https://www.iaai.com';
export enum StatusCodes {
  HTTP_SUCCESS = 200,
  OK = 201,
  USER_EXISTS = 302,
  USER_NOT_EXISTS_OR_WRONG_PASSWORD = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

export const Months = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listapad',
  'Grudzień',
];

export enum ModalEvents {
  CLOSE_EVENT = 'modal_close',
  CANCEL_EVENT = 'modal_cancel',
  OK_EVENT = 'modal_ok',
  TIME_CHANGED_EVENT = 'time_changed',
  DATE_CHANGED_EVENT = 'date_changed',
}

export enum PageMode {
  PREVIEW = 'preview',
  NEW = 'new',
  EDIT = 'edit',
}

export enum MessageType {
  INFO = 0,
  WARNING = 1,
  DANGER = 2,
}

export enum Settings {
  SR_IMPORT_DATE_FORMAT = 'SR_IMPORT_DATE_FORMAT',
  SR_IMPORT_REGEX = 'SR_IMPORT_REGEX',
  SR_IMPORT_SEPARATOR = 'SR_IMPORT_SEPARATOR',
}

export enum OrderStatuses{
  COMISSIONED = 1,
  REJECTED = 2,
  PAID = 3,
  TRANSPORT_IN_AMERICA = 4,
  TRANSPORT_TO_EUROPE = 5,
  WAITING_FOR_CUSTOMS = 6,
  CUSTOM_DUTY_PAID = 7,
  TRANSPORT_TO_COUNTRY = 8,
  DURING_EXCISE = 9,
  EXCISE_PAID = 10,
  COMMISSION_PAID = 11,
  DONE = 12,
  OUTSOURCED = 13,
  CANCELLED = 14,
  COMISSIONED_FOR_PURCHASE = 14,
}
export const purchasedOrderStatuses = [
  OrderStatuses.PAID,
  OrderStatuses.TRANSPORT_IN_AMERICA,
  OrderStatuses.TRANSPORT_TO_EUROPE,
  OrderStatuses.WAITING_FOR_CUSTOMS,
  OrderStatuses.CUSTOM_DUTY_PAID,
  OrderStatuses.TRANSPORT_TO_COUNTRY,
  OrderStatuses.DURING_EXCISE,
  OrderStatuses.EXCISE_PAID,
  OrderStatuses.COMMISSION_PAID,
  OrderStatuses.DONE,
  OrderStatuses.OUTSOURCED,
];
export const statusesList = [
  {text: 'Zlecone do licytacji', value: OrderStatuses.COMISSIONED},
  {text: 'Zlecone do zakupu', value: OrderStatuses.COMISSIONED_FOR_PURCHASE},
  {text: 'Odrzucone', value: OrderStatuses.REJECTED},
  {text: 'Opłacone', value: OrderStatuses.PAID},
  {text: 'Transport Ameryka', value: OrderStatuses.TRANSPORT_IN_AMERICA},
  {text: 'Transport do Europy', value: OrderStatuses.TRANSPORT_TO_EUROPE},
  {text: 'Oczekiwanie na cło', value: OrderStatuses.WAITING_FOR_CUSTOMS},
  {text: 'Opłacone cło', value: OrderStatuses.CUSTOM_DUTY_PAID},
  {text: 'Transport do kraju', value: OrderStatuses.TRANSPORT_TO_COUNTRY},
  {text: 'W trakcie akcyzy', value: OrderStatuses.DURING_EXCISE},
  {text: 'Akcyza opłacona', value: OrderStatuses.EXCISE_PAID},
  {text: 'Prowizja opłacona', value: OrderStatuses.COMMISSION_PAID},
  {text: 'Wykonane', value: OrderStatuses.DONE},
  {text: 'Zlecone z zewnątrz', value: OrderStatuses.OUTSOURCED},
  {text: 'Anulowane', value: OrderStatuses.CANCELLED},
];

export const capacityCurrencies = [
  {text: 'USD', value: 'USD'},
  {text: 'EUR', value: 'EUR'},
  {text: 'PLN', value: 'PLN'},
];
export enum auctionTypesValues{
  COPART = 0,
  IAAI = 1,
  OTHERS = 2
}
export const auctionTypes = [
  {text: 'Copart', value: auctionTypesValues.COPART},
  {text: 'IAAI', value: auctionTypesValues.IAAI},
  {text: 'Inne', value: auctionTypesValues.OTHERS},
];
export enum customAgenciesValues {
  DEFAULT = 0
}
export const customAgencies = [
  {text: 'Domyślna', value: customAgenciesValues.DEFAULT},
];
export const API = Object.freeze({
  LOGIN: '/login',
  REGISTER: '/register',
  LOGOUT: '/logout',
  RESET: '/reset',
  SET_USER_CONTEXT: '/setUserContext',
});


// Ultimately, this will be data from database
function createYearList(){
  const yearsList: any[] = [];
  for (let i = 1950; i <= 2022; i++){
    yearsList.push(i);
  }
  return yearsList;
}
export const Years = createYearList();
// Ultimately, this will be data from database


export function getDateInSpecificFormat(incrementDays?){
  let ddString = '', mmString = '';
  const today = new Date();
  if(typeof incrementDays !== 'undefined'){
    today.setDate(today.getDate() + incrementDays);
  }
  const dd = today.getDate();
  const mm = today.getMonth()+1;
  const yyyy = today.getFullYear();
  ddString = dd < 10 ? '0' + dd: dd.toString();
  mmString = mm < 10 ? '0' + mm: mm.toString();

  return (yyyy + mmString + ddString);
}

export const auctionImageDefaultSource = "https://damian.uico.pl/142.Corpline/auction-default.jpg";

export const filtersDataFieldsArray = [
  {"key": "vehicle_type", "before": "", "after": ""},
  {"key": "make", "before": "", "after": ""},
  {"key": "model_detail", "before": "", "after": ""},
  {"key": "year__gte", "before": "Od", "after": ""},
  {"key": "year__lte", "before": "Do", "after": ""},
  {"key": "odometer__gte", "before": "Od", "after": "mi"},
  {"key": "odometer__lte", "before": "Do", "after": "mi"},
  {"key": "cylinders__gte", "before": "Od", "after": ""},
  {"key": "cylinders__lte", "before": "Do", "after": ""},
  {"key": "fuel_type", "before": "", "after": ""},
  {"key": "drive", "before": "", "after": ""},
  {"key": "price__gte", "before": "Od", "after": "$"},
  {"key": "price__lte", "before": "Do", "after": "$"},
  {"key": "sale_title_type", "before": "", "after": ""},
  {"key": "sale_status", "before": "", "after": ""},
  {"key": "engine", "before": "Silnik:", "after": ""},
];

export const defaultHelpList = [
  {
    id: 0,
    'title': 'Czym jest Copart oraz IAAI ?',
    'text': 'Są to największe domy aukcyjne, specjalizujące się w licytacjach pojazdów od ubezpieczalni. Zarówno Copart jak i IAAI posiadają oddziały rozlokowane na terenie całych Stanów Zjednoczonych przez co zakup pojazdu z USA jest możliwy z dowolnego regionu.',
  },
  {
    id: 1,
    'title': 'Czy wszystkie samochody znajdujące się na aukcjach są powypadkowe ?',
    'text': 'Nie. Na aukcjach możemy znaleźć również auta bezwypadkowe ale z zarejestrowaną szkodą przez ubezpieczalnię jak np. auta po kradzieżowe lub z uszkodzonymi elementami mechanicznymi',
  },
  {
    id: 2,
    'title': 'Czy zakup samochodu z USA jest bezpieczny ?',
    'text': 'Zakup każdego pojazdu wiąże się z ryzykiem, zarówno takiego który należy importować aż z za oceanu jak i również samochodu który możemy fizycznie na miejscu zobaczyć. Ryzyko minimalizowane jest dzięki obsłudze przez naszą firmę. Wykorzystując nasze doświadczenie i porady, możesz liczyć na udany zakup. ',
  },
  {
    id: 3,
    'title': 'Jak określić koszt naprawy danego pojazdu ?',
    'text': 'Jeśli decydujesz się na kompleksową obsługę wraz z naprawą, szacujemy wartość napraw danej szkody z podziałem na zakres prac i zakup części. W naszej wycenie bazujemy na doświadczeniu i codziennej pracy z uszkodzonymi pojazdami dzięki czemu jesteśmy w stanie szczegółowo określić możliwe uszkodzenia, a co za tym idzie i koszt napraw. Jeśli decydujesz się na naprawę we własnym zakresie, skontaktuj się z wybranym warsztatem - dobry fachowiec na podstawie zdjęć z aukcji będzie w stanie oszacować wspomniany powyżej zakres.',
  },
  {
    id: 4,
    'title': 'Ile czasu trwa import pojazdu?',
    'text': 'Czas realizacji jest uzależniony od lokalizacji pojazdu na terenie USA - więcej szczegółów znajdziesz w zakładce "Transport"',
  },
  {
    id: 5,
    'title': 'Czy moje pieniądze są bezpieczne ?',
    'text': 'Tak. Wybierając współpracę z naszą firmą, wybierasz markę która na rynku funkcjonuje od przeszło 10 lat. Na koncie mamy setki realizacji i mnóstwo zadowolonych klientów. Staramy się aby cały proces był dla Ciebie transparentny. Bezpieczeństwo na każdym etapie importu jest dla nas priorytetem. Więcej o samych opłatach znajdziesz w zakładce "Opłaty"',
  },
  {
    id: 6,
    'title': 'Jakich title (dokumentów własności) należy unikać ?',
    'text': 'Wraz z zakupionym samochodem, otrzymujemy dokument własności - title, odpowiednik naszego dowodu rejestracyjnego. Owy dokumenty jest charakterystyczny dla każdego stanu oraz posiada określony status/tytuł. Najczęściej spotykanym Title - jest Salvage Title. Dokument ten w sporej większości pozwala na ponowną rejestrację w Polsce po wcześniejszej naprawie i przejściu badania technicznego. Na aukcjach możemy wyróżnić jednak pewne Title, w przypadku których rejestracja czy też sam import jest niemożliwy lub bardzo problematyczny. Poniżej lista dokumentów które należy unikać\n' +
        'ACQ BILL OF SALE\n' +
        'ACQUISITION BILL OF SALE\n' +
        'ARTIFICERS LIEN TRANSFER\n' +
        'ABND/LIEN NC DLR ONLY DELAY \n' +
        'BILL OF SALE\n' +
        'CLEAN TITLE - NO VIN\n' +
        'CLEAN TITLE W/ UNSATIS LIEN\n' +
        'CERTYFICATE OF DESTRUCTION  \n' +
        'CERT OF SALV BRANDED FLOOD \n' +
        'DISMANTLER W/ UNSATISFIED LIEN\n' +
        'EXPORT BILL OF SALE\n' +
        'FOR JUNK\n' +
        'FLOOD TITLE\n' +
        'FIRE DAMAGE\n' +
        'FOR PARTS ONLY \n' +
        'FLOOD SALVAGE VEHICLE TITLE\n' +
        'MV907A WAIV-272.1\n' +
        'MV37 P.O. DISM ONLY\n' +
        'MV907A W/ BRAND WAIVE\n' +
        'MV907A W/ NO PUBLIC VIN\n' +
        'MV907A W/ UNSATISFIED LIEN\n' +
        'MV-51B / STMT OF OWNERSHIP\n' +
        'MV907A W/ MD REASSIGNMENT\n' +
        'MV35 NO TITLE-DISMANTLE/SCRAP\n' +
        'NY-MV-51\n' +
        'NON-REBUILABLE\n' +
        'NON-REPAIRABLE\n' +
        'PARTS ONLY\n' +
        'PARTS ONLY W/NY REASSIGMENT\n' +
        'REBUILDABLE KIT CAR\n' +
        'REBLT SALV W/ UNSATISFIED LIEN\n' +
        'SALVAGE THEFT - NO PUBLIC VIN\n' +
        'SALVAGE TITLE DAMAGE WATER/FLOOD\n' +
        'WATER/FLOOD\n' +
        'WATER DAMAGE',
  },
  {
    id: 7,
    'title': 'Czy licytuję pojazdy na aukcji osobiście?',
    'text': ' Proces licytacji następuje automatycznie po zatwierdzeniu kwoty do jakiej chcemy licytować dany egzemplarz',
  },
];

export const carSearchForm = {
  vehicle_type: '',
  make: '',
  model_detail: '',
  year__gte: '',
  year__lte: '',
  odometer__gte: '',
  odometer__lte: '',
  cylinders__gte: '',
  cylinders__lte: '',
  fuel_type: '',
  drive: '',
  high_bid__gte: '',
  high_bid__lte: '',
  sale_title_type: '',
  sale_status: '',

  model: '',
  driveline_type: '',
  seller_type: '',
  title: '',
  fuel: '',
  cylinder__gte: '',
  cylinder__lte: '',
  current_bid__gte: '',
  current_bid__lte: '',
  engine: []
}
export const ADMIN_USER_ID = 153;
