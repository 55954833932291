









































import {Component, Vue, Prop, Watch, Emit} from 'vue-property-decorator';
import * as consts from '../../consts';
import * as API from "@/store/api";
@Component({
  components: {
  },
})
export default class ModalDeleteTransaction extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ required: true, default: null }) transaction;
  transactionResponseInfo: { success: boolean; show: boolean; message: string } = {
    show: null,
    success: null,
    message: null,
  };
  size = 'sm';
  show = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];

  @Watch('transaction')
  transactionChange(val, oldVal) {
    this.transaction = val;
  }

  showModal(params = {}) {
    this.show = true;
  }

  hideModal() {
    this.show = false;
  }
  @Emit('transaction-removed')
  transactionRemoved(){
    this.hideModal();
    return true;
  }
  async deleteTransaction(){
    this.transactionResponseInfo = {
      show: false,
      success: false,
      message: '',
    };
    const response = await API.deleteTransaction(this.transaction.id);
    if(!response.success){
      this.transactionResponseInfo.show = true;
      this.transactionResponseInfo.success = false;
      this.transactionResponseInfo.message = 'Błąd! Proszę spróbować ponownie ponownie później.';
    }else{
      this.transactionRemoved();
    }
  }
}
