

























import * as API from '../../store/api';
import auth from '../../store/modules/auth';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import RegistrationForm from '@/components/spec/RegistrationForm.vue';
import {StatusCodes} from "@/consts";
@Component({
  components: {
    RegistrationForm,
  },
})
export default class ModalRegistration extends Vue {
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: '' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  // @Prop({ type: String, required: false, default: 'lg' }) size;
  @Prop({ type: Boolean, required: false, default: false }) backClose;
  @Prop({ type: Boolean, required: false, default: false }) okOnly;
  // @Prop({ type: Boolean, required: false, default: true }) okVisible;
  @Prop({ type: String, required: false, default: 'success' }) okVariant;
  @Prop({ type: Boolean, required: false, default: false }) disabledOkButton;
  @Prop({ type: Boolean, required: false, default: true }) hideOnSave;
  @Prop({ type: Number, required: false }) widthPercentage;

  size = 'sm';
  showRegistrationForm = true;
  okVisible = false;
  buttonOKText = 'Zarejestruj';
  enabled = false;
  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  revalidate = 0;
  btnSave = 0;

  registrationResponseInfo = {
    show: false,
    status: 0,
    message: '',
  };
  registrationForm = {
    email: '',
    password: '',
    repeatedPassword: '',
    checked: [],
  };
  showModal(params = {}) {
    this.show = true;
    // this.params = params;
    // console.log('Params', this.params);
  }
  saveModal() {
    this.save = true;
    this.$emit('SAVE', this.params);
    this.btnSave++;
    if (this.hideOnSave) {
      this.show = false;
    }
  }
  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }

  @Emit("register-success")
  registerSuccess(){
    this.hideModal();
    return StatusCodes.OK;
  }
  async register(registrationForm) {
    this.registrationResponseInfo.show = false;
    this.registrationForm = registrationForm;
    if(this.registrationForm){
      console.log('Wynik: ' + JSON.stringify(this.registrationForm));

      const response = await API.registerUser({
        email: this.registrationForm.email,
        password: this.registrationForm.password,
      });

      switch(response){
        case StatusCodes.OK:
          console.log('success! ' + response);
          this.registerSuccess();
          break;
        case StatusCodes.USER_EXISTS:
          console.log('User already exists! ' + response);
          this.registrationResponseInfo.show = true;
          this.registrationResponseInfo.status = StatusCodes.USER_EXISTS;
          this.registrationResponseInfo.message = 'Użytkownik z podanym adresem e-mail już istnieje.';

          break;
        default:
          console.log('Register error! ' + response);
          this.registrationResponseInfo.show = true;
          this.registrationResponseInfo.status = -1;
          this.registrationResponseInfo.message = 'Wysyłanie formularza nie powiodło się. Proszę spróbować ponownie.';
      }
      // this.$router.push({ path: '/' });
    }
  }
}
