





















import {Component, Prop, Vue} from "vue-property-decorator";
import ImageCard from "@/components/dummy/ImageCard.vue";
@Component({
  components: {
    ImageCard,
  },
})
export default class ImageCardsGrid extends Vue{
  @Prop({ type: String, required: false, default: "Popularne marki" }) title;

  brands = [
    {
      name: 'Toyota',
    },
    {
      name: 'Ford',
    },
    {
      name: 'Chevrolet',
    },
    {
      name: 'Jeep',
    },
    {
      name: 'GMC',
    },
    {
      name: 'Buick',
    },
    {
      name: 'Dodge',
    },
    {
      name: 'RAM',
    },
    {
      name: 'Tesla',
    },
    {
      name: 'Volkswagen',
    },

    {
      name: 'GMC',
    },
    {
      name: 'Buick',
    },
    {
      name: 'Dodge',
    },
    {
      name: 'Toyota',
    },
    {
      name: 'Ford',
    },
    {
      name: 'Chevrolet',
    },
    {
      name: 'Jeep',
    },
    {
      name: 'GMC',
    },
    {
      name: 'RAM',
    },
    {
      name: 'Toyota',
    },
    {
      name: 'Ford',
    },
    {
      name: 'Chevrolet',
    },
    {
      name: 'Tesla',
    },
    {
      name: 'Volkswagen',
    },
  ]

}
