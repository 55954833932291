


























































import {Component, Vue, Prop} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class UsersListTable extends Vue {
  @Prop({ type: String, required: false, default: "Lista użytkowników" }) sectionTitle;
  @Prop({ type: Array, required: true, default: [] }) usersList;
}
