























































import {Component, Vue, Ref, Prop, Emit, Watch} from 'vue-property-decorator';
import * as consts from '../../consts';
import * as API from "@/store/api";
import {StatusCodes} from "@/consts";
@Component({
  components: {
  },
})
export default class CRMOrderListTable extends Vue {
  @Prop({ type: Array, required: false, default: [] }) orderList;

  modifiedOrderList = [];
  @Emit('show-add-order-modal')
  showAddOrderModal(){
    return true;
  }

}
