



























































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import * as consts from "@/consts";
import * as API from "@/store/api";
@Component({
  components: {
  },
})
export default class Gallery extends Vue {
  @Prop({ type: String }) copartImages;
  @Prop({ type: Array }) cargoImages;
  @Prop({ type: Array }) iaaiImages;

  imagesArrayWithThumbnail = [];
  imgDefault = consts.auctionImageDefaultSource;
  loading = false;

  lotImages = null;

  showNoImagesAlert = false;
  hasImages = 1;
  copartImagesArray = [
    [
      0,
      this.imgDefault
    ],
  ];

  async getCopartImages(){
    this.loading = true;
    const last  = '/' + this.copartImages.split("/").pop();
    const galleryJson = { "param" : last };
    const response = await API.getAuctionImages(galleryJson);
    if(typeof response === "object"){
      if(typeof response.lotImages !== 'undefined'){
        this.lotImages = response.lotImages;
        const linksJson = [];
        for(let i = 0; i < this.lotImages.length; i++){
          const linkObject = JSON.parse(JSON.stringify(this.lotImages[i]["link"]));
          let url;
          for (const linkEl of Object.keys(linkObject)) {
            let isHdImage = null;
            let isThumbNail = null;
            let tmpUrl = null;
            isHdImage = linkObject[linkEl]["isHdImage"];
            isThumbNail = linkObject[linkEl]["isThumbNail"];
            tmpUrl = linkObject[linkEl]["url"];
            if(!isThumbNail && isHdImage){
              url = tmpUrl;
              break;
            }
            if(!isThumbNail && !isHdImage){
              url = tmpUrl;
              break;
            }
            if(isThumbNail && !isHdImage){
              url = tmpUrl;
            }
          }
          linksJson.push([i, url]);
        }
        if(!linksJson.length){
          linksJson.push([0, this.imgDefault]);
        }
        this.copartImagesArray = linksJson;
      }else{
        this.hasImages = 0;
        this.showNoImagesAlert = true;
      }
    }else{
      console.log('BLAD  ' + response);
      this.hasImages = 0;
      this.showNoImagesAlert = true;
    }
    this.loading = false;
  }
  getCargoImages(){
    console.log('getCargoImages');
    this.imagesArrayWithThumbnail = this.cargoImages;
    const lastElement = {'URL': this.imgDefault};
    this.imagesArrayWithThumbnail.unshift(lastElement);
  }

  mounted(){
    if(this.copartImages){
      this.getCopartImages();
    }else if(this.cargoImages){
      this.getCargoImages();
    }
  }
}
