

























































































import auth from '../../store/modules/auth';
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderLoginButton from "@/components/spec/HeaderLoginButton.vue";
import HeaderSearchElement from '../../components/dummy/HeaderSearchElement.vue';
import LanguageSwitcherElement from './LanguageSwitcherElement.vue';
import HeaderProfile from "@/components/dummy/HeaderProfile.vue";
import * as API from '../../store/api';
import * as helpers from "@/helpers";
import * as types from "@/types";
import store from "@/store";

@Component({
  components: {
    HeaderLoginButton,
    LanguageSwitcherElement,
    HeaderSearchElement,
    HeaderProfile
  },
})
export default class Header extends Vue {
  userData = null;
  isAdmin = false;
  async logout() {
    // this.$cookies.remove('user');
    await store.commit('setCurrentUser');
    auth.clear();
    this.checkUserType();
    await API.logoutUser();
    if(this.$router.currentRoute.path !== '/'){
      this.$router.push('/');
    }
  }
  loginSuccess(){
    console.log('login-success');
    this.checkUserType();
  }
  currentRouteName() {
    return this.$route.name;
  }
  getUser(){
    return this.$store.state.currentUser? this.$store.state.currentUser: null;
  }
  private checkUserType(){
    this.isAdmin = false;
    this.userData = this.getUser();
    if(this.userData){
      if(Object.prototype.hasOwnProperty.call(this.userData, 'is_superuser')){
        if(this.userData.is_superuser === true){
          this.isAdmin = true;
        }
      }
    }
  }
  mounted(){
    this.checkUserType();
  }
}
