










































import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import Accordion from "@/components/spec/Accordion.vue";
import AuctionBidDetails from "@/components/dummy/AuctionBidDetails.vue";
import AuctionDetails from "@/components/spec/AuctionDetails.vue";
import AuctionReportsOrder from "@/components/spec/AuctionReportsOrder.vue";
import ExciseTaxCostCalculator from "@/components/dummy/ExciseTaxCostCalculator.vue";
import Gallery from "@/components/dummy/Gallery.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
import PurchaseCostCalculator from "@/components/dummy/PurchaseCostCalculator.vue";
import * as API from "@/store/api";
import * as consts from '@/consts';
import {getSingleAuctionIaai} from "@/store/api";
@Component({
  components: {
    PageHeader,
    PurchaseCostCalculator,
    AuctionDetails,
    Gallery,
    AuctionBidDetails,
    AuctionReportsOrder,
    Accordion,
    ExciseTaxCostCalculator,
  },
})
export default class SingleAuctionIaai extends Vue {
  pageName = null;
  auctionData = null;
  JSONData = null;
  vehicleHref = null;
  breadcrumbItems =  []
  data = null;
  routeQuery = null;
  vin = null;
  private helpList = [];
  private stateSymbol = '';
  private userId = null;
  private userOrders = [];
  private offerExistsInUserBids = null;
  createListForBreadcrumbs(){
    this.breadcrumbItems =  [
      {
        text: 'Strona główna',
        to: {name: 'home'}
      },
      {
        text: 'Licytacje IAAI',
        to: {name: 'auctionsIaai'}
      },
      {
        text: this.pageName,
        active: true
      }
    ]
  }
  async getAuctionInfo() {
    if(this.$route.params.obj){
      this.auctionData = this.$route.params.obj;
    }else{
      this.routeQuery = JSON.parse(JSON.stringify(this.$route.query));
      const singleAuctionIaaiResponse = await API.getSingleAuctionIaai(this.routeQuery.id);
      if(typeof singleAuctionIaaiResponse === "object"){
        if(singleAuctionIaaiResponse.objs.length){
          this.auctionData = singleAuctionIaaiResponse.objs[0];
        }else{
          await this.$router.push({name: 'auctionsIaai'});
        }
      }else{
        await this.$router.push({name: 'auctionsIaai'});
      }
    }
    this.pageName = this.auctionData.make + ' ' + this.auctionData.model;
    this.stateSymbol = this.auctionData.state;
    console.log(this.auctionData);
  }
  private async getUserOrders() {
    const response = await API.getUserOrders(this.userId);
    if (response.success) {
      return response.user_orders;
    }
    return [];
  }

  async checkUserOrder() {
    this.offerExistsInUserBids = false;
    if (this.$store.state.currentUser.id) {
      this.userId = this.$store.state.currentUser.id;
      this.userOrders = await this.getUserOrders();
      if(this.userOrders.length){
        const tmpAuctionData = this.auctionData;
        const tmpFiltered = this.userOrders.filter(function (el){
          return (el.VIN === tmpAuctionData.VIN &&
              el.offer_number === tmpAuctionData.lot_number &&
              el.auction_type === consts.auctionTypesValues.COPART
          );
        })
        this.offerExistsInUserBids = !!tmpFiltered.length;
      }
    }
  }

  async mounted() {
    await this.getAuctionInfo();
    this.createListForBreadcrumbs();
    this.helpList = consts.defaultHelpList;
    await this.checkUserOrder();
  }
}
