import axios from 'axios';

// declare let my_ajax_object: {
//   ajax_nonce: string;
// };

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://corpline-cms.uico.pl/wp-json/';

const cmsApi = axios.create();

export async function getPageFromCMS(templateName) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'corpline/v1/page/template/template-' + templateName + '-page.php';

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function getOptionsFromCMS() {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'corpline/v1/options/config-page';

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function getOptionsPurchaseCalculatorFromCMS() {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'corpline/v1/options/purchase-calculator';

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export function getSectionsFromCMS(CMSPage) {
  if (Object.prototype.hasOwnProperty.call(CMSPage, 'sections')) {
    return CMSPage['sections'];
  }
}

export async function getStatesFromCMS() {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'corpline/v1/states';

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function getStateFromCMS(stateSymbol) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'corpline/v1/states/' + stateSymbol;

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function matchViewsToPagesFromCMS(pageID) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'wp/v2/pages/' + pageID;

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function getSearchResultsFromCMS(search) {
  const headers = {
    'Content-Type': 'application/json',
  };
  let response;
  const getValue = 'wp/v2/search/?search=' + search;

  await cmsApi
    .get(getValue, {
      withCredentials: true,
    })
    .then(res => {
      response = res.data;
    })
    .catch(err => {
      response = err;
    });
  return response;
}

export async function sendEmail(contactForm) {
  const name = contactForm['name'];
  const email = contactForm['email'];
  const message = contactForm['message'];
  const payload = {
    name,
    email,
    message,
    // security,
  };

  let response;
  const getValue = 'corpline/v1/contact-form';

  await cmsApi
      .post(getValue, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        response = res.data;
      })
      .catch(err => {
        response = {"type": "err"};
      });
  return response;

}

