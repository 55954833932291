


















import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class AuctionReportsOrder extends Vue {
  @Prop() vin;
  carfaxHref = 'https://carfax-report.online/pl/';
  autoDnaHref = 'https://www.autodna.pl/vin/' + this.vin;
}
