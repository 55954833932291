
























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import * as consts from '../../consts';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expire: '1y'})

@Component
export default class ModalAction extends Vue {
  @Prop({ type: String, required: false, default: 'Zgoda na wykorzystywanie plików cookies' }) title;
  @Prop({ type: String, required: false, default: 'Ta strona używa plików cookies. Korzystając z serwisu wyrażasz ' +
        'zgodę na ich używanie, bez zmiany ustawień przeglądarki.' }) message;
  @Prop({ type: String, required: false, default: '' }) messageClass;
  @Prop({ type: String, required: false, default: '' }) titleClass;
  @Prop({ type: String, required: false, default: 'lg' }) size;

  show = false;
  save = false;
  events: [consts.ModalEvents.OK_EVENT, consts.ModalEvents.CANCEL_EVENT];
  params = {};
  lastMove = {};
  revalidate = 0;
  comment = '';
  btnSave = 0;

  @Emit()
  handleOk() {
    this.$cookies.set('cookieConsent', 'ok');
    return 1;
  }

  hideModal() {
    this.params['save'] = this.save;
    this.$emit(consts.ModalEvents.CANCEL_EVENT, this.params);
    this.show = false;
  }
  mounted(){
    if(!this.$cookies.isKey('cookieConsent')){
      this.show = true;
    }
  }
}
