



















































































import {Component, Prop, Vue} from "vue-property-decorator";
import Accordion from "@/components/spec/Accordion.vue";
import AuctionsCarousel from "@/components/spec/AuctionsCarousel.vue";
import AuctionsGrid from "@/components/spec/AuctionsGrid.vue";
import BannerWithCarSearch from "@/components/spec/BannerWithCarSearch.vue";
import BannerWithContent from "@/components/dummy/BannerWithContent.vue";
import ElectricVehicleProfitabilityCalculator from "@/components/spec/ElectricVehicleProfitabilityCalculator.vue";
import Embed from "@/components/dummy/Embed.vue";
import ImageCardsGrid from "@/components/dummy/ImageCardsGrid.vue";
import ImageContentRow from "@/components/dummy/ImageContentRow.vue";
import Steps from "@/components/spec/Steps.vue";
import TextSection from "@/components/dummy/TextSection.vue";
import * as CMS from "@/store/cms";

@Component({
  components: {
    Accordion,
    AuctionsCarousel,
    AuctionsGrid,
    BannerWithCarSearch,
    BannerWithContent,
    Embed,
    ElectricVehicleProfitabilityCalculator,
    ImageCardsGrid,
    ImageContentRow,
    Steps,
    TextSection,
  }
})

export default class Sections extends Vue{
  @Prop({ type: String, required: true, default: "" }) templateName;

  CMSPage;
  sections;
  hasSections = false;
  async getPageFromCMS() {
    const response = await CMS.getPageFromCMS(this.templateName);
    if(response.length){
      this.CMSPage = (response[0]);
      this.createSections();
    }
  }
  createSections(){
    this.sections = CMS.getSectionsFromCMS(this.CMSPage);
    if(Object.prototype.hasOwnProperty.call(this.sections, 'page_sections')){
      this.sections = this.sections['page_sections'];
      this.hasSections = true;
    }
  }
  get CMSSections(){
    if(this.hasSections){
      return this.sections;
    }else{
      return false;
    }
  }
  checkImageFromCMS(item, imgNode, size){
    if(Object.prototype.hasOwnProperty.call(item, imgNode)){
      const images = item[imgNode];
      if(Object.prototype.hasOwnProperty.call(images, 'sizes')){
        const imgSizes = images['sizes'];
        if(Object.prototype.hasOwnProperty.call(imgSizes, size)){
          const img = imgSizes[size];
          return img;
        }
      }
    }
    return '';
  }
  created() {
    this.getPageFromCMS();
  }
}
