import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AboutUs from '@/views/AboutUs.vue';
import Account from '@/views/Account.vue';
import Activate from '../views/Activate.vue';
import AuctionsCopart from '@/views/AuctionsCopart.vue';
import AuctionsIaai from '@/views/AuctionsIaai.vue';
import Calculators from '@/views/Calculators.vue';
import Contact from '@/views/Contact.vue';
import Dashboard from '../views/mainview/Dashboard.vue';
import Electromobility from '@/views/Electromobility.vue';
import Fees from '@/views/Fees.vue';
import Home from '@/views/Home.vue';
import Help from '@/views/Help.vue';
import MainView from '../views/MainView.vue';
import NoAuthorization from '@/views/NoAuthorization.vue';
import Order from '@/views/Order.vue';
import Orders from '@/views/Orders.vue';
import OrdersList from '@/views/admin/OrdersList.vue';
import Payments from '@/views/Payments.vue';
import PurchasedVehicles from '@/views/PurchasedVehicles.vue';
import ProjectInformation from '@/views/ProjectInformation.vue';
import Repair from '@/views/Repair.vue';
import SearchResults from '@/views/SearchResults.vue';
import SingleAuction from '@/views/SingleAuction.vue';
import SingleAuctionIaai from '@/views/SingleAuctionIaai.vue';
import SingleOrder from '@/views/admin/SingleOrder.vue';
import SingleUser from '@/views/admin/SingleUser.vue';
import SinglePurchasedVehicle from '@/views/SinglePurchasedVehicle.vue';
import Terms from '@/views/Terms.vue';
import Test from '../views/Test.vue';
import Transactions from '@/views/Transactions.vue';
import Transport from '@/views/Transport.vue';
import UsersList from '@/views/admin/UsersList.vue';
import WatchedListOfVehicles from '@/views/WatchedListOfVehicles.vue';

import auth from '@/store/modules/auth';
import * as API from '@/store/api';
import store from '@/store';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    alias: '/home',
  },
  {
    path: '/licytacje-copart',
    name: 'auctionsCopart',
    redirect: '/licytacje-copart/1',
  },
  {
    path: '/licytacje-copart/:page?',
    name: 'auctionsCopart',
    component: AuctionsCopart,
    props: true,
  },
  {
    path: '/licytacje-iaai',
    name: 'auctionsIaai',
    redirect: '/licytacje-iaai/1',
  },
  {
    path: '/licytacje-iaai/:page?',
    name: 'auctionsIaai',
    component: AuctionsIaai,
    props: true,
  },
  {
    path: '/lot/',
    name: 'singleAuction',
    component: SingleAuction,
  },
  {
    path: '/stock/',
    name: 'singleAuctionIaai',
    component: SingleAuctionIaai,
  },
  {
    path: '/kupione',
    name: 'purchasedVehicles',
    component: PurchasedVehicles,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/kupione/pojazd',
    name: 'singlePurchasedVehicle',
    component: SinglePurchasedVehicle,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/activate',
    name: 'activate',
    component: Activate,
  },
  {
    path: '/konto',
    name: 'account',
    component: Account,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transport',
    name: 'transport',
    component: Transport,
  },
  {
    path: '/oplaty',
    name: 'fees',
    component: Fees,
  },
  {
    path: '/naprawa',
    name: 'repair',
    component: Repair,
  },
  {
    path: '/elektromobilnosc',
    name: 'electromobility',
    component: Electromobility,
  },
  {
    path: '/o-nas',
    name: 'aboutUs',
    component: AboutUs,
  },
  {
    path: '/pomoc',
    name: 'help',
    component: Help,
  },
  {
    path: '/kontakt',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/rozliczenia',
    name: 'payments',
    component: Payments,
  },
  {
    path: '/regulamin',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/brak-autoryzacji',
    name: 'noAuthorization',
    component: NoAuthorization,
  },
  {
    path: '/wyniki-wyszukiwania/',
    name: 'searchResults',
    component: SearchResults,
  },
  {
    path: '/informacje-o-projekcie/',
    name: 'projectInformation',
    component: ProjectInformation,
  },
  {
    path: '/test/',
    name: 'Test',
    component: Test,
  },
  {
    path: '/obserwowane/',
    name: 'watchedListOfVehicles',
    component: WatchedListOfVehicles,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/kalkulatory/',
    name: 'calculators',
    component: Calculators,
  },
  {
    path: '/zlecenia/',
    name: 'ordersList',
    component: OrdersList,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/zlecenia/:id',
    name: 'singleOrder',
    component: SingleOrder,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/uzytkownicy/',
    name: 'usersList',
    component: UsersList,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/uzytkownicy/:userId',
    name: 'singleUser',
    component: SingleUser,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/moje-transakcje',
    name: 'transactions',
    component: Transactions,
    props: true,
    meta: {
      requiresSuperUser: true,
      requiresAuth: true,
    },
  },
  {
    path: '/moje-zlecenia/',
    name: 'orders',
    component: Orders,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/moje-zlecenia/:id',
    name: 'order',
    component: Order,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mainview/',
    component: MainView,
    props: true,
    children: [
      { path: '', redirect: 'dashboard' },

      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await API.getSession();
  if (auth.isAuthenticated === false) {
    store.commit('setCurrentUser');
    auth.clear();
    await API.logoutUser();
  }
  window.scrollTo(0, 0);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth.isAuthenticated === false) {
      next({ name: 'noAuthorization' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresSuperUser)) {
    if (auth.isSuperUser === true) {
      next();
    } else {
      next({ name: 'noAuthorization' });
    }
  } else {
    next();
  }
});

export default router;
