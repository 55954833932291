









import {Component, Vue, Prop, Emit} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class QuantityInput extends Vue {
  @Prop({ type: Number }) quantityValue;
  @Prop({ default: '', type: String }) quantityUnit;
  @Prop({ default: 100 }) quantityStep;
  value = 0;

  @Emit('update-bid-price')
  increaseValue(){
    this.value += parseFloat(this.quantityStep);
    return this.value;
  }
  @Emit('update-bid-price')
  decreaseValue(){
    this.value -= parseFloat(this.quantityStep);
    return this.value;
  }
  mounted(){
    this.value = this.quantityValue;
  }
}
