








import {Component, Vue} from 'vue-property-decorator';
import Sections from "@/components/dummy/Sections.vue";

@Component({
  components: {
    Sections
  },
})
export default class ProjectInformation extends Vue {
  templateName = 'projectInformation';
}
