





































import { Component, Vue, Prop, Ref, Emit, Watch } from 'vue-property-decorator';
import * as consts from '../../consts';
import ModalChooseTime from '@/components/dummy/ModalChooseTime.vue';
import moment from 'moment';

@Component({
  components: {
    ModalChooseTime,
  },
})
export default class DateInput extends Vue {
  @Ref() readonly modalChooseTime: ModalChooseTime;
  @Prop({ type: String, required: false, default: '' }) title;
  @Prop({ type: Boolean, default: true }) enabled;
  @Prop({ default: null }) value;
  events: [consts.ModalEvents.DATE_CHANGED_EVENT];
  dateDbFormat = '';
  dateText = null;

  @Emit('date-change')
  onMainDateContext(ctx) {
    console.log('onMainDateContext');
    if (ctx.selectedDate !== null) {
      const dt = moment(ctx.selectedFormatted, consts.DATE_FORMAT);
      if (dt.isValid()) this.dateDbFormat = dt.format(consts.DB_DATE_TIME_FORMAT);
      // this.dateText = moment(this.dateDbFormat, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT);
      this.dateText = moment(this.dateDbFormat, consts.DB_DATE_TIME_FORMAT).format(consts.DATE_FORMAT2);
      console.log('formatt');
      console.log(this.dateText);
      return this.dateText;
    }else{
      console.log(ctx.activeYMD);
      return ctx.activeYMD;
    }
  }

  @Emit('date-change')
  dateTextLeave(event: any) {
    console.log('dateTextLeave');
    if (!moment(event.target.value, consts.DATE_FORMAT, true).isValid()) {
      const today = moment();
      this.dateDbFormat = today.format(consts.DB_DATE_TIME_FORMAT);
      this.dateText = today.format(consts.DATE_FORMAT);
    } else {
      this.dateDbFormat = moment(event.target.value, consts.DATE_FORMAT).format(consts.DB_DATE_TIME_FORMAT);
    }
    return this.dateDbFormat;
  }

  @Watch('value')
  valueChange(val, oldVal) {
    this.value = val;
    this.setValue();
  }
  reset() {
    this.dateText = '';
    this.$forceUpdate();
  }
  setValue(){
    this.dateText = null;
    if(this.value){
      const tmpPosition = this.value.indexOf('T');
      if(tmpPosition !== -1){
        this.dateText = this.value.slice(0, tmpPosition);
      }else{
        this.dateText = this.value;
      }
    }else{
      this.dateText = '';
    }
    console.log('this.dateText');
    console.log(this.dateText);
  }
  mounted(){
    this.setValue();
  }
}
