










































































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {required, between} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import FormSelect from "@/components/dummy/FormSelect.vue";
import * as API from "@/store/api";
import * as CMS from "@/store/cms";

@Component({
  components: {
    FormSelect
  },
  mixins: [validationMixin],
  validations: {
    calculatorForm: {
      purchaseCost: {
        required,
        between: between(0, 999999)
      },
      doorTransport: {
      },
      documentTranslations: {
      },
      expertOpinion: {
      },
      exciseDeclaration: {
      },
    },
  },
})
export default class PurchaseCostCalculator extends mixins(Vue, validationMixin){
  @Prop({ required: false, default: "" }) stateSymbol;
  calculatorForm = {
    purchaseCost: 0,
    doorTransport: '',
    documentTranslations: '',
    expertOpinion: '',
    exciseDeclaration: '',
  }
  private withCurrencies = false;
  private withValues = false;

  // set default values
  private defaultDoorTransport = 2000;
  private defaultDocumentTranslations = 400;
  private defaultExpertOpinion = 500;
  private defaultExciseDeclaration = 150;
  private defaultFixedAuctionHouseCommission = 250;
  private defaultBroker = 250;
  private defaultCustomsAgency = 480;
  // set default values

  // set default values to final variables
  private doorTransport = this.defaultDoorTransport;
  private documentTranslations = this.defaultDocumentTranslations;
  private expertOpinion = this.defaultExpertOpinion;
  private exciseDeclaration = this.defaultExciseDeclaration;
  private fixedAuctionHouseCommission = this.defaultFixedAuctionHouseCommission;
  private broker = this.defaultBroker;
  private customsAgency = this.defaultCustomsAgency;
  private auctionHouseCommissionCalculated : any = this.fixedAuctionHouseCommission;
  private dutyCalculated : any = 0;
  private vatCalculated : any = 0;
  // set default values to final variables

  private USD;
  private EUR;
  private exchangeCommission = 0.01;
  private stateCost;
  private harborCost;
  private withTransportCostsInAmerica = false;
  contactCheckboxStatus = null;

  purchaseCalcOptions;
  hasPurchaseCalcOptions = false;
  async getOptionsPurchaseCalculatorFromCMS() {
    const response = await CMS.getOptionsPurchaseCalculatorFromCMS();
    if(typeof response === "object"){
      this.purchaseCalcOptions = (response);
      this.hasPurchaseCalcOptions = true;
    }
    this.setValuesFromCMS();
  }
  calculationResult(){
    let doorTransportCost = 0;
    let documentTranslationsCost = 0;
    let expertOpinionCost = 0;
    let exciseDeclarationCost = 0;
    let fixedAuctionHouseCommissionCost = 0;
    let stateCostVal = 0;
    let harborCostVal = 0;

    if(this.$v.calculatorForm.purchaseCost.$model){
      if(this.$v.calculatorForm.purchaseCost.$model > 0){

        if(this.fixedAuctionHouseCommission){
          fixedAuctionHouseCommissionCost = this.fixedAuctionHouseCommission;
        }
        if(this.$v.calculatorForm.doorTransport.$model){
          doorTransportCost = this.doorTransport;
        }
        if(this.$v.calculatorForm.documentTranslations.$model){
          documentTranslationsCost = this.documentTranslations;
        }
        if(this.$v.calculatorForm.expertOpinion.$model){
          expertOpinionCost = this.expertOpinion;
        }
        if(this.$v.calculatorForm.exciseDeclaration.$model){
          exciseDeclarationCost = this.exciseDeclaration;
        }

        if(this.withTransportCostsInAmerica){
          stateCostVal = parseFloat(this.stateCost);
          harborCostVal = parseFloat(this.harborCost);
        }

        const auctionHouseCommission = this.getAuctionHouseCommissionCalculatedVal(this.$v.calculatorForm.purchaseCost.$model, 0.09, fixedAuctionHouseCommissionCost);
        // this.auctionHouseCommissionCalculated = ((this.$v.calculatorForm.purchaseCost.$model * 0.09) + fixedAuctionHouseCommissionCost).toFixed(2);
        // this.auctionHouseCommissionCalculated = parseFloat(this.auctionHouseCommissionCalculated);
        const duty = this.getDutyCalculatedVal(this.$v.calculatorForm.purchaseCost.$model, 0.1);
        const vat = this.getVatCalculatedVal(this.$v.calculatorForm.purchaseCost.$model, 0.19);
        // this.dutyCalculated = (this.$v.calculatorForm.purchaseCost.$model * 0.1).toFixed(2);
        // this.dutyCalculated = parseFloat(this.dutyCalculated);
        // this.vatCalculated = (this.$v.calculatorForm.purchaseCost.$model * 0.19).toFixed(2);
        // this.vatCalculated = parseFloat(this.vatCalculated);

        const resultsUSD = Math.round(((this.$v.calculatorForm.purchaseCost.$model + auctionHouseCommission + this.broker + duty + vat + stateCostVal + harborCostVal) * 100) / 100) * this.USD;

        const resultsEur = this.customsAgency * this.EUR;

        const resultsPLN = doorTransportCost + documentTranslationsCost + expertOpinionCost + exciseDeclarationCost;
        return Math.round(resultsUSD + resultsEur + resultsPLN);
      }
    }else{
      return '';
    }
  }
  getAuctionHouseCommissionCalculatedVal(purchaseCost, percent, fixedAuctionHouseCommissionCost){
    let returnVal : any = ((purchaseCost * percent) + fixedAuctionHouseCommissionCost).toFixed(2);
    returnVal = parseFloat(returnVal);
    this.auctionHouseCommissionCalculated = returnVal;
    return returnVal;
  }
  getDutyCalculatedVal(purchaseCost, percent){
    let returnVal : any = (purchaseCost * percent).toFixed(2);
    returnVal = parseFloat(returnVal);
    this.dutyCalculated = returnVal;
    return returnVal;
  }
  getVatCalculatedVal(purchaseCost, percent){
    let returnVal : any = (purchaseCost * percent).toFixed(2);
    returnVal = parseFloat(returnVal);
    this.vatCalculated = returnVal;
    return returnVal;
  }
  checkboxCheck(event){
    if (event) {
      this.contactCheckboxStatus = true;
      return true;
    }else{
      this.contactCheckboxStatus = false;
      return false;
    }
  }
  calculatorSubmit(){
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false;
    } else {
      return this.calculatorForm;
    }
  }
  getNumberInFloat(value){
    const tmpFloatValue = parseFloat(value);
    return parseFloat(tmpFloatValue.toFixed(2));
  }
  setValuesFromCMS(){
    if(this.hasPurchaseCalcOptions){
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_broker'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_broker']).length){
        this.broker = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_broker']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_customs_agency'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_customs_agency']).length){
        this.customsAgency = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_customs_agency']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_document_translations'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_document_translations']).length){
        this.documentTranslations = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_document_translations']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_door_transport'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_door_transport']).length){
        this.doorTransport = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_door_transport']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_excise_declaration'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_excise_declaration']).length){
        this.exciseDeclaration = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_excise_declaration']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_expert_opinion'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_expert_opinion']).length){
        this.expertOpinion = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_expert_opinion']);
      }
      if(this.purchaseCalcOptions['option_page_purchase_cost_calc_fixed_auction_house_commission'] !== 'undefined' && (this.purchaseCalcOptions['option_page_purchase_cost_calc_fixed_auction_house_commission']).length){
        this.fixedAuctionHouseCommission = this.getNumberInFloat(this.purchaseCalcOptions['option_page_purchase_cost_calc_fixed_auction_house_commission']);
      }
    }
    this.withValues = true;
  }
  getStateCost(){
    return this.stateCost;
  }
  getHarborCost(){
    return this.harborCost;
  }
  async getFx(code){
    const response = await API.getFx(code);
    if(typeof response === 'object'){
      if(response[0] === 201){
        return response[1].fx.rates[0].mid;
      }else{
        return -1;
      }
    }else{
      return -1;
    }
  }
  async setCurrencies(){
    const USDApi = await this.getFx('USD');
    const EURApi = await this.getFx('EUR');
    if(USDApi !== -1){
      this.USD = USDApi + this.exchangeCommission;
      this.EUR = EURApi + this.exchangeCommission;
      this.withCurrencies = true;
    }else{
      this.withCurrencies = false;
    }
  }
  async setStateHarborCosts(){
    let stateAPI = await this.getStateFromCMS();
    if(stateAPI !== -1){
      stateAPI = stateAPI[0];
      if(Object.prototype.hasOwnProperty.call(stateAPI, 'fields')){
        const stateFields = stateAPI['fields'];
        if(Object.prototype.hasOwnProperty.call(stateFields, 'states_price')) {
          this.stateCost = stateFields['states_price'];
        }
      }
      if(Object.prototype.hasOwnProperty.call(stateAPI, 'harbor')){
        const stateHarbor = stateAPI['harbor'];
        if(Object.prototype.hasOwnProperty.call(stateHarbor, 'harbor_price')) {
          this.harborCost = stateHarbor['harbor_price'];
        }
      }
      if(this.stateCost && this.harborCost){
        this.withTransportCostsInAmerica = true;
      }
    }
  }
  async getStateFromCMS() {
    const response = await CMS.getStateFromCMS(this.stateSymbol);
    if(response.length === 1){
      return response;
    }else{
      return -1;
    }
  }
  mounted(){
    this.setCurrencies();
    this.setStateHarborCosts();
    this.getOptionsPurchaseCalculatorFromCMS();
  }
}
