















import { Component, Vue } from 'vue-property-decorator';
import ModalLogin from "@/components/spec/ModalLogin.vue";
import ModalRegistration from "@/components/spec/ModalRegistration.vue";
import ModalInfo from "@/components/dummy/ModalInfo.vue";
import ModalAccountDetails from "@/components/spec/ModalAccountDetails.vue";

@Component({
  components: {
    ModalLogin,
    ModalRegistration,
    ModalAccountDetails,
    ModalInfo,
  },
})
export default class Modal extends Vue {
  modalVisible = false;
  isNew = false;
  fetchedAll = false;

  hideModal() {
    this.modalVisible = false;
  }
  save() {
    this.hideModal();
  }

  async showModal(isNew: boolean) {
    this.isNew = isNew;
    this.modalVisible = true;
  }

  async created() {
    this.fetchedAll = false;
    this.fetchedAll = true;
  }
}
