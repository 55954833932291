






























import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class Embed extends Vue{
  @Prop({ type: String, required: false, default: "default" }) type;
  @Prop({ type: String, required: false, default: "https://damian.uico.pl/142.Corpline/tab1.jpg" }) frameSrc;
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) text;
  @Prop({ type: String, required: false, default: "" }) buttonText;
  @Prop({ type: String, required: false, default: "/" }) buttonRouterPath;
}
