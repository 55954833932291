
























import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
import PaymentInfo from "@/components/spec/PaymentInfo.vue";
import PageHeader from "@/components/dummy/PageHeader.vue";
@Component({
  components: {
    AccountPaymentInfo: PaymentInfo,
    PageHeader,
  },
})
export default class Payments extends Vue {
  pageName = 'Rozliczenia';
  breadcrumbItems =  [
    {
      text: 'Strona główna',
      to: {name: 'home'}
    },
    {
      text: this.pageName,
      active: true
    },
  ]
  data = [];

}
