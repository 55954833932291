
















































































































































import {Vue, Component, Prop, Emit, Watch} from "vue-property-decorator";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import FormSelect from "@/components/dummy/FormSelect.vue";
import * as API from '@/store/api';
import * as consts from '../../consts';

@Component({
  components: {
    FormSelect,
  },
  mixins: [validationMixin],
  validations: {
    orderData: {
      makeModel: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      vin: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(48),
      },
      user: {
      },
      offerNumber: {
        required,
        maxLength: maxLength(48),
      },
      auctionType: {
        maxLength: maxLength(48),
      },
      orderAmount: {
        required,
        maxLength: maxLength(48),
      },
      orderAmountCurrency: {
        required,
      },
      status: {
        maxLength: maxLength(48),
      },
      customsAgency: {
      },
    }
  },
})
export default class AddEditOrderForm extends Vue{
  @Prop({ type: Boolean, required: false, default: false }) isEditView;
  @Prop({ required: false, default: null }) order;
  @Prop({ required: false, default: null }) isSuccess;
  loading = true;
  showError = false;
  orderData = {
    makeModel: null,
    vin: null,
    user: null,
    offerNumber: null,
    auctionType: null,
    orderAmount: null,
    orderAmountCurrency: null,
    status: null,
    customsAgency: null,
  }

  auctionTypes = consts.auctionTypes;
  usersList = null;
  statusesList = consts.statusesList;
  customsAgencies = consts.customAgencies;
  capacityCurrencies = consts.capacityCurrencies;
  selectedStatus = 0;
  selectedUser = 0;
  selectedAuctionType = 0;
  selectedCustomsAgency = 1;
  selectedCurrency = this.capacityCurrencies[0].value;

  @Watch('isSuccess')
  successChange(val, oldVal) {
    this.isSuccess = val;
  }
  auctionTypeChange(value){
    this.orderData['auctionType'] = value;
  }
  userChange(value){
    this.orderData['user'] = value;
  }
  statusChange(value){
    this.orderData['status'] = value;
  }
  currencyChange(value){
    this.orderData['orderAmountCurrency'] = value;
  }
  customsAgencyChange(value){
    this.orderData['customsAgency'] = value;
  }
  @Emit("order-form-approved")
  async orderSubmit(event) {
    event.preventDefault()
    return this.orderData;
  }
  setDefaultValues(){
    this.orderData = {
      makeModel: this.order.make,
      vin: this.order.VIN,
      user: this.order.user_id,
      offerNumber: this.order.offer_number,
      auctionType: this.order.auction_type,
      orderAmount: this.order.amount,
      orderAmountCurrency: this.order.amount_currency,
      status: this.order.status,
      customsAgency: this.order.custom_agency,
    }
    this.selectedStatus = parseInt(this.orderData.status);
    this.selectedUser = parseInt(this.orderData.user);
    this.selectedAuctionType = parseInt(this.orderData.auctionType);
    this.selectedCustomsAgency = parseInt(this.orderData.customsAgency);
    this.selectedCurrency = this.orderData.orderAmountCurrency;
    this.loading = false;
  }
  async getUsers() {
    const usersResponse = await API.getAllUsers();
    if (typeof usersResponse === 'object') {
      if (usersResponse.success) {
        const tmpUsersList = [];
        usersResponse.users.forEach(function (value) {
          tmpUsersList.push({"value": value.id, "text": value.email});
        })
        this.usersList = tmpUsersList;
      }
    }
    this.loading = false;
  }
  async mounted(){
    this.loading = true;
    await this.getUsers();
    if(this.isEditView && this.order){
      this.setDefaultValues();
    }
  }
}
