























import {Component, Prop, Vue} from "vue-property-decorator";
import Breadcrumbs from "@/components/dummy/Breadcrumbs.vue";
@Component({
  components: {
    Breadcrumbs,
  }
})
export default class PageHeader extends Vue{
  @Prop({ type: String, required: false, default: "" }) pageName;
  @Prop({ type: Boolean, required: false, default: false }) customHeader;
  @Prop({ type: Array, required: false, default: "" }) breadcrumbItems;
  @Prop({ type: Boolean, required: false, default: false }) isAdmin;
  @Prop({ type: String, required: false, default: "" }) goBackViewName;
}
