











































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class PaginationLarge extends Vue{
  @Prop({ type: Number, required: false, default: 2 }) maxVisibleButtons;
  @Prop({ type: Number, required: true, default: 3 }) totalElements;
  @Prop({ type: Number, required: true, default: 12 }) perPage;
  @Prop({ type: Number, required: true, default: 1 }) currentPage;

  get totalPages(){
    return this.totalElements / this.perPage >=1? Math.floor(this.totalElements / this.perPage ): 1;
  }
  get startPage()  {
    if (this.currentPage === 1) {
      return 1;
    }
    if (this.currentPage === this.totalPages) {
      return this.totalPages - this.maxVisibleButtons;
    }
    return this.currentPage - 1;
  }
  get pages() {
    const range = [];
    let comparisonTmp = Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    for (let i = this.startPage; i <= comparisonTmp; i++) {
      range.push({
        name: i,
        isDisabled: i === this.currentPage
      });
      comparisonTmp = Math.min(this.startPage + this.maxVisibleButtons, this.totalPages);
    }

    return range;
  }

  get isInFirstPage() {
    return this.currentPage === 1;
  }
  get isInLastPage() {
    return this.currentPage === this.totalPages;
  }
  onClickPreviousPage() {
    this.$emit('pagechanged', this.currentPage - 1);
  }
  onClickPage(page) {
    this.$emit('pagechanged', page);
  }
  onClickNextPage() {
    this.$emit('pagechanged', this.currentPage + 1);
  }
  onClickLastPage() {
    this.$emit('pagechanged', this.totalPages);
  }
  isPageActive(page) {
    return this.currentPage === page;
  }
}
