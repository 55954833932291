










import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class Breadcrumbs extends Vue{
  @Prop({ type: Array, required: false, default: "" }) breadcrumbItems;
}
