




















































































































































































import {Vue, Component, Emit, Prop} from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { mixins } from 'vue-class-component';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import FormSelect from '@/components/dummy/FormSelect.vue';

@Component({
	components: {
		FormSelect,
	},
	mixins: [validationMixin],
	validations: {
    accountData: {
      contact: {
        street_and_house_no: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        city: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        postal_code: {
          required,
        },
        phone: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
      },
      invoice: {
        name: {
          maxLength: maxLength(48),
        },
        nip: {
          maxLength: maxLength(48),
        },
        street_and_house_no: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        postal_code: {

        },
        city: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
      },
      delivery: {
        name: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        street_and_house_no: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        postal_code: {
        },
        city: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
        point_type: {
          maxLength: maxLength(48),
        },
        phone: {
          minLength: minLength(2),
          maxLength: maxLength(48),
        },
      },
    }
  },
})
export default class AccountBasicDataEdit extends mixins(Vue, validationMixin) {
  @Prop({ default: '', }) accountData;

  submitStatus = '';
  deliveryTypes = [
    { value: '0', text: 'Warsztat' },
    { value: '1', text: 'Osoba prywatna' },
  ];
  selectedDeliveryType = 0;

	@Emit('account-basic-data-submitted')
	basicDataSubmit() {
    return this.accountData;
	}

	@Emit('account-basic-data-back')
	basicDataBack(event) {
		return true;
	}

  deliveryTypeChange(value){
    this.accountData['delivery'].point_type = value;
  }
  mounted(){
    console.log(this.accountData);
    if(this.accountData.delivery.point_type === '1'){
      this.selectedDeliveryType = 1;
    }else{
      this.selectedDeliveryType = 0;
    }
  }
}
