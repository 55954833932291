












import * as API from '../store/api';
import { Component, Vue, Ref } from 'vue-property-decorator';
import ModalInfo from "@/components/dummy/ModalInfo.vue";

@Component({
  components: {
    ModalInfo,
  },
})
export default class Activate extends Vue {
  @Ref() readonly InfoModal: ModalInfo
  infoModalTitle = '';
  infoModalText = 'Po zamknięciu tego okna zostaniesz przekierowany do strony głównej.';

  hideModalInfo(){
    this.$router.push('/');
  }

  async activateRequest() {
    const response = await API.activateUser({
      email: this.$route.query.email,
      token: this.$route.query.token,
    });
    console.log(response);
    if(response === undefined){
      this.infoModalTitle = 'Wystąpił błąd!';
    }
    if(!response.success){
      switch (response.error_code) {
        case 2:
          this.infoModalTitle = 'Użytkownik nie istnieje!';
          break;
        case 4:
          this.infoModalTitle = 'Brak autoryzacji!';
          break;
      }
    }else{
      this.infoModalTitle = 'Twoje konto zostało aktywowane!';
    }
    this.InfoModal.showModal();

  }

  mounted() {
    if(this.$route.query.email !== undefined && this.$route.query.token !== undefined){
      this.activateRequest();
      console.log('EMAIL:', this.$route.query.email, 'TOKEN:', this.$route.query.token);
    }else{
      this.infoModalTitle = 'Wystąpił błąd!';
      this.infoModalText = 'Po zamknięciu tego okna zostaniesz przekierowany do strony głównej.';
      this.InfoModal.showModal();
    }
  }
}
