
























import {Component, Vue, Ref, Prop} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class BackgroundImageWithContent extends Vue {
  @Prop({ type: String, required: false, default: "https://damian.uico.pl/142.Corpline/default-background-image.jpg" }) imageSrc;
  @Prop({ type: String, required: false, default: "Import samochodów z USA" }) title;
  @Prop({ type: String, required: false, default: "" }) text;
  @Prop({ type: String, required: false, default: "Wszystkie Licytacje" }) buttonText;
  @Prop({ required: false, default: "" }) buttonRouterPath;
  @Prop({ type: String, required: false, default: "" }) buttonLink;
  @Prop({ type: String, required: false, default: "" }) buttonTarget;


}
