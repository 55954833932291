












































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {required, minLength, email} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { VueRecaptcha } from "vue-recaptcha";
import * as CMS from "@/store/cms";

@Component({
  mixins: [validationMixin],
  validations: {
    contactForm: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      message: {
        required,
        minLength: minLength(6)
      },
      checked: {
        required
      },
    },
  },
  components: {
    VueRecaptcha,
  }
})

export default class ContactForm extends mixins(Vue, validationMixin){
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) text;
  @Prop({ type: String, required: false, default: "" }) phoneNumber;
  formStatus = null;
  form = {
    robot: false,
  }
  contactForm = {
    name: '',
    email: '',
    message: '',
    checked: '',
  }
  submitStatus = ''
  contactCheckboxStatus = null;
  checkboxCheck(event){
    if (event) {
      this.contactCheckboxStatus = true;
      return true;
    }else{
      this.contactCheckboxStatus = false;
      return false;
    }
  }

  async contactSubmit() {
    if (this.checkboxCheck(this.contactForm.checked) == true) {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false;
      } else {
        this.formStatus = 'pending';
        const response = await CMS.sendEmail(this.contactForm);
        if(typeof response['type'] !== 'undefined'){
          if(response['type'] === 'ok'){
            this.formStatus = 'success';
          }else{
            this.formStatus = 'error';
          }
        }else{
          this.formStatus = 'error';
        }
      }
    } else {
      return false;
    }
  }
  onVerify(resposne){
    if(resposne){
      this.form.robot = true;
    }
  }
}
