













import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {

  }
})
export default class ImageCard extends Vue{
  @Prop({ type: String, required: false, default: "https://damian.uico.pl/142.Corpline/auction-default.jpg" }) imageSrc;
  @Prop({ type: String, required: false, default: "" }) name;

  goToAuctionsWithData() {
    window.scrollTo(0,0);
    const routerQueryJSON = {make: this.name};
    console.log(routerQueryJSON);

    this.$router.push({
      name: 'auctionsCopart',
      query: routerQueryJSON
    });
    // this.routeQuery = JSON.stringify(this.$route.query);
  }
}
